import { writable } from 'svelte/store';

export const hosts = {
	"anz": "https://api.anz",
	"cba": "https://api.commbank.com.au/public",
	"nab": "https://openbank.api.nab.com.au",
	"wbc": "https://digital-api.westpac.com.au"}
		
export const endPoint = '/cds-au/v1/banking/products'

export const myHeaders = {
	// 'mode': 'cors',
	// 'Sec-Fetch-Mode':'cors',
	// 'x-v':'1',
	// 'Accept': 'application/json'
	}

	// 'Host': '*',
	// 'Access-Control-Allow-Origin': '*',
	// 'Access-Control-Allow-Headers': '*',
	// 'Access-Control-Allow-Methods': '*',
	// 'Access-Control-Request-Headers': '*',
	// 'Access-Control-Request-Method': '*',
	// 'Origin': '*',
	
// export constant anzProductList = require('../public/data/anz/anzProductListResponse.json')

export const pageSize = "1000"
export const effective = "ALL"

export const depositsViewStore = writable(0)
export const lendingsViewStore = writable(0)
export const feesViewStore = writable(0)
export const featuresViewStore = writable(0)
export const eligibilityViewStore = writable(0)
export const constraintsViewStore = writable(0)
export const bundlesViewStore = writable(0)

export const nameStore = writable(0)
export const descriptionStore = writable(0)
export const isTailoredStore = writable(0)
export const depositRatesStore = writable(0)
export const lendingRatesStore = writable(0)
export const feesStore = writable(0)
export const featuresStore = writable(0)
export const eligibilityStore = writable(0)
export const constraintsStore = writable(0)
export const bundlesStore = writable(0)


export const applicationUriStore = writable(0)
export const effectiveFromStore = writable(0)
export const effectiveToStore = writable(0)
export const lastUpdatedStore = writable(0)
export const additionalInformationStore = writable(0)

export const depositsOrderStore = writable(0)
export const lendingOrderStore = writable(0)
export const feesOrderStore = writable(0)
export const featuresOrderStore = writable(0)
export const eligibilityOrderStore = writable(0)
export const constraintsOrderStore = writable(0)
export const bundlesOrderStore = writable(0)
export const trackCardOrderStore = writable(0)

//stores for records of rows
export const depositRatesRecordsStore = writable(0)
export const lendingRatesRecordsStore = writable(0)
export const feesRecordsStore = writable(0)
export const featuresRecordsStore = writable(0)
export const eligibilityRecordsStore = writable(0)
export const constraintsRecordsStore = writable(0)
export const bundlesRecordsStore = writable(0)
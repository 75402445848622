<script>
  import { afterUpdate } from "svelte";
  import tippy from "tippy.js";
  import { getContext } from "svelte";

  export let name;
  export let description;

  export let depositRates;
  export let lendingRates;
  export let fees;
  export let features;
  export let eligibility;
  export let constraints;
  export let bundles;

  export let isTailored;
  export let additionalInformation;
  export let applicationUri;
  export let effectiveFrom;
  export let effectiveTo;
  export let lastUpdated;
  export let overviewUri;
  export let feesAndPricingUri;
  export let eligibilityUri;
  export let termsUri;
  export let bundleUri;

  //try add records number
  export let depositRatesRecords;
  export let lendingRatesRecords;
  export let feesRecords;
  export let featuresRecords;
  export let eligibilityRecords;
  export let constraintsRecords;
  export let bundlesRecords;

  import {
    depositsViewStore,
    lendingsViewStore,
    feesViewStore,
    featuresViewStore,
    eligibilityViewStore,
    constraintsViewStore,
    bundlesViewStore,
    depositsOrderStore,
    lendingOrderStore,
    feesOrderStore,
    featuresOrderStore,
    eligibilityOrderStore,
    constraintsOrderStore,
    bundlesOrderStore,
    trackCardOrderStore
  } from "./stores.js";

  function depositsDecreaseCounter() {
    $trackCardOrderStore = $trackCardOrderStore - 1;
    $depositsOrderStore = $trackCardOrderStore;
  }

  function lendingDecreaseCounter() {
    $trackCardOrderStore = $trackCardOrderStore - 1;
    $lendingOrderStore = $trackCardOrderStore;
  }

  function feesDecreaseCounter() {
    $trackCardOrderStore = $trackCardOrderStore - 1;
    $feesOrderStore = $trackCardOrderStore;
  }

  function featuresDecreaseCounter() {
    $trackCardOrderStore = $trackCardOrderStore - 1;
    $featuresOrderStore = $trackCardOrderStore;
  }

  function eligibilityDecreaseCounter() {
    $trackCardOrderStore = $trackCardOrderStore - 1;
    $eligibilityOrderStore = $trackCardOrderStore;
  }

  function constraintsDecreaseCounter() {
    $trackCardOrderStore = $trackCardOrderStore - 1;
    $constraintsOrderStore = $trackCardOrderStore;
  }

  function bundlesDecreaseCounter() {
    $trackCardOrderStore = $trackCardOrderStore - 1;
    $bundlesOrderStore = $trackCardOrderStore;
  }

  function sortChildrenDivsById() {
    const main = document.querySelector("#productdetails");
    const divs = [...main.children];
    divs.sort((a, b) => a.id - b.id);
    divs.forEach(div => main.appendChild(div));
  }

  afterUpdate(() => {
    sortChildrenDivsById();
  });

  //handle the close buttons on the tabs
  function handleDepositsClose() {
    $depositsViewStore = false;
  }

  function handleLendingsClose() {
    $lendingsViewStore = false;
  }

  function handleFeesClose() {
    $feesViewStore = false;
  }

  function handleFeaturesClose() {
    $featuresViewStore = false;
  }

  function handleEligibilityClose() {
    $eligibilityViewStore = false;
  }

  function handleConstraintsClose() {
    $constraintsViewStore = false;
  }

  const timePeriodsISO = {
    content:
      "P denotes period<br>Y = Year<br>M = Month<br>W = Week<br>D = Day<br><br>T denotes Time<br>H = Hour<br>M = Minute<br>S = Second",
    allowHTML: true
  };

  // ##deposit tippys##

  const tippyDepositFixed = {
    content: "Fixed rate for a period of time"
  };

  const tippyDepositBonus = {
    content: "A bonus rate available by meeting a specific criteria"
  };

  const tippyDepositBundle = {
    content:
      "A bonus rate obtained by originating a bundle instead of a standalone product"
  };

  const tippyDepositVariable = {
    content: "A variable base rate for the product"
  };

  const tippyDepositIntroductory = {
    content: "An introductory bonus that will expire after a set period"
  };

  const tippyDepositFloating = {
    content:
      "A floating rate is relatively fixed but still adjusts under specific circumstances"
  };

  const tippyDepositMarket = {
    content:
      "A rate that is linked to a specific market, commodity or asset class"
  };

  // ##lending tippys##

  const tippyLendingFixed = {
    content: "Fixed rate for a period of time"
  };

  const tippyLendingVariable = {
    content: "A variable base rate for the product"
  };

  const tippyLendingIntroductory = {
    content: "An introductory discount that will expire after a set period"
  };

  const tippyLendingDiscount = {
    content:
      "A specific discount rate that may be applied. A discount rate reduces the interest payable"
  };

  const tippyLendingPenalty = {
    content:
      "A specific penalty rate that may be applied. A penalty rate increases the interest payable"
  };

  const tippyLendingFloating = {
    content:
      "A floating rate is relatively fixed but still adjusts under specific circumstances"
  };

  const tippyLendingMarket = {
    content:
      "A rate that is linked to a specific market, commodity or asset class"
  };

  const tippyLendingCash = {
    content: "Specific rate applied to cash advances from the account"
  };

  const tippyLendingPurchase = {
    content: "Specific rate applied to purchases from the account"
  };

  const tippyLendingBundleFixed = {
    content:
      "A discount rate off the fixed rate obtained by originating a bundle instead of a standalone product"
  };

  const tippyLendingBundleVariable = {
    content:
      "A discount rate off the variable rate obtained by originating a bundle instead of a standalone product"
  };

  // ## fee tippys ##

  const tippyFeePeriodic = {
    content: "A periodic fee such as a monthly account servicing fee"
  };

  const tippyFeeTransaction = {
    content:
      "A fee associated with any transaction (incorporates WITHDRAWAL, DEPOSIT, PAYMENT and PURCHASE)"
  };

  const tippyFeeWithdrawal = {
    content: "A fee associated with making a withdrawal"
  };

  const tippyFeeDeposit = {
    content: "A fee associated with making a deposit"
  };

  const tippyFeePayment = {
    content: "A fee associated with making a payment"
  };

  const tippyFeePurchase = {
    content: "A fee associated with making a purchase at a merchant"
  };

  const tippyFeeEvent = {
    content:
      "A fee in relation to a particular event (e.g. NA ordering a new card, viewing a balance or stopping a cheque)"
  };

  const tippyFeeUpfront = {
    content:
      "A fee paid at the beginning of the product NA lifecycle, such as an establishment fee, loyalty program fee or application fee"
  };

  const tippyFeeExit = {
    content: "A fee for closing the product"
  };

  // feature tippys

  const tippyFeatureCard = {
    content: "A card is available for the product to access funds"
  };

  const tippyFeatureAdditional = {
    content: "Additional cards can be requested"
  };

  const tippyFeatureUnlimted = {
    content: "Unlimited free transactions available"
  };

  const tippyFeatureFreeTxns = {
    content: "A set number of free transactions available per month"
  };

  const tippyFeatureFreeTxnsAllowance = {
    content:
      "A set amount of transaction fee value that is discounted per month"
  };

  const tippyFeatureLoyalty = {
    content: "A points based loyalty program is available"
  };

  const tippyFeatureOffset = {
    content: "An offset account can be connected to the product"
  };

  const tippyFeatureOverdraft = {
    content: "An overdraft can be applied for"
  };

  const tippyFeatureRedraw = {
    content: "Redraw of repaid principal above minimum required is available"
  };

  const tippyFeatureInsurance = {
    content: "Insurance is provided as an additional feature of the product	"
  };

  const tippyFeatureBalance = {
    content:
      "Balance transfers can be made to the account (eg. for credit cards)"
  };

  const tippyFeatureInterestFree = {
    content: "Interest free period for purchases"
  };

  const tippyFeatureInterestFreeTransfers = {
    content: "Interest free period for balance transfers"
  };

  const tippyFeatureDigitalWallet = {
    content: "A Digital wallet can be attached to the product"
  };

  const tippyFeatureDigitalBanking = {
    content: "Access is available to online banking features for the product"
  };

  const tippyFeatureNppPay = {
    content:
      "An account of this product type can be used as the target of an NPP PayID"
  };

  const tippyFeatureNppEnabled = {
    content:
      "An account of this product type can be used to receive funds as a result of a BSB/Number based NPP payment"
  };

  const tippyFeatureDonate = {
    content:
      "Indicates that interest generated from the product can be automatically donated to a charity or community group"
  };

  const tippyFeatureBill = {
    content:
      "The product can be attached to an automatic budgeting and bill payment service"
  };

  const tippyFeatureComplementary = {
    content:
      "Indicates that complementary, discounted offerings (such as gift cards, or discounted travel) is available"
  };

  const tippyFeatureBonus = {
    content: "Bonus loyalty rewards points are available"
  };

  const tippyFeatureNotifications = {
    content: "Advanced notifications are available for the product"
  };

  const tippyFeatureOther = {
    content:
      "Another feature that can not be included in any of the other categories"
  };

  // eligibility tippys

  const tippyEligibilityBusiness = {
    content: "Only business may apply for the account"
  };

  const tippyEligibilityPension = {
    content: "A recipient of a government pension may apply for the product"
  };

  const tippyEligibilityMinAge = {
    content: "Only customers older than a minimum age may apply"
  };

  const tippyEligibilityMaxAge = {
    content: "Only customers younger than a maximum age may apply"
  };

  const tippyEligibilityMinIncome = {
    content:
      "The customer must have an income greater than a specified threshold to obtain the product"
  };

  const tippyEligibilityMinTurnover = {
    content: "Only a business with greater than a minimum turnover may apply"
  };

  const tippyEligibilityStaff = {
    content: "Only a staff member of the provider may apply"
  };

  const tippyEligibilityStudent = {
    content: "Only students may apply for the product"
  };

  const tippyEligibilityEmployment = {
    content: "An eligibility constraint based on employment status applies"
  };

  const tippyEligibilityResidency = {
    content: "An eligibility constraint based on residency status applies"
  };

  const tippyEligibilityNatural = {
    content:
      "The customer must be a natural person rather than another legal entity"
  };

  const tippyEligibilityOther = {
    content:
      "Another eligibility criteria exists as described in the additional info field"
  };

  // ## constraint tippys ##

  const tippyConstraintMinBalance = {
    content: "A minimum balance is required for the product"
  };

  const tippyConstraintMaxBalance = {
    content: "A maximum balance is required for the product"
  };

  const tippyConstraintOpening = {
    content: "An opening balance is required for the product"
  };

  const tippyConstraintMinLimit = {
    content:
      "A maximum limit exists (such as a maximum loan balance denoting the borrowable amount or maximum allowable credit limit)"
  };

  const tippyConstraintMaxLimit = {
    content:
      "A minimum limit exists (such as a minimum borrowable amount or minimum credit limit)"
  };

  // ## bundle tippys

  //----- ## SUB-HEADING TIPPYS ## -----

  //fees table

  const tippyFeeHeadingAmount = {
    content: "The amount charged for the fee"
  };

  const tippyFeeHeadingBalance = {
    content: "A fee rate calculated based on a proportion of the balance"
  };

  const tippyFeeHeadingTransaction = {
    content: "A fee rate calculated based on a proportion of a transaction"
  };

  const tippyFeeHeadingAccrued = {
    content:
      "A fee rate calculated based on a proportion of the calculated interest accrued on the account"
  };

  const tippyFeeHeadingAccrual = {
    content:
      "The indicative frequency with which the fee is calculated on the account"
  };

  //discounts table

  const tippyFeeDiscountTypeHeadingBalance = {
    content:
      "Discount on a fee for maintaining a set balance. As the discount applies to a fee the period is the same as for the fee"
  };

  const tippyFeeDiscountTypeHeadingDeposits = {
    content:
      "Discount for depositing a certain amount of money in a period. As the discount applies to a fee the period is the same as for the fee"
  };

  const tippyFeeDiscountTypeHeadingPayments = {
    content:
      "Discount for outbound payments from the account under a certain amount of money in a period. As the discount applies to a fee the period is the same as for the fee"
  };

  const tippyFeeDiscountTypeHeadingFee = {
    content:
      "The amount, balanceRate, transactionRate or calculatedInterestRate fields of the discount represent the maximum amount charged in a time period"
  };

  const tippyFeeDiscountTypeHeadingEligibility = {
    content: "Discount applies based on customer eligibility"
  };

  //eligiblity table

  const tippyFeeDiscountEligibilityTypeHeadingBusiness = {
    content: "A business or other non-person legal entity"
  };

  const tippyFeeDiscountEligibilityTypeHeadingPension = {
    content: "A recipient of a government pension may receive the discount"
  };

  const tippyFeeDiscountEligibilityTypeHeadingMinAge = {
    content: "Only customers older than a minimum age receive the discount"
  };

  const tippyFeeDiscountEligibilityTypeHeadingMaxAge = {
    content: "Only customers younger than a maximum age receive the discount"
  };

  const tippyFeeDiscountEligibilityTypeHeadingMinIncome = {
    content:
      "The customer must have an income greater than a specified threshold to obtain the discount"
  };

  const tippyFeeDiscountEligibilityTypeHeadingMinTurnover = {
    content: "Only a business with greater than a minimum turnover is eligible"
  };

  const tippyFeeDiscountEligibilityTypeHeadingStaff = {
    content: "Only a staff member of the provider may receive the discount"
  };

  const tippyFeeDiscountEligibilityTypeHeadingStudent = {
    content: "Only students may receive the discount"
  };

  const tippyFeeDiscountEligibilityTypeHeadingEmployment = {
    content: "An eligibility constraint based on employment status applies"
  };

  const tippyFeeDiscountEligibilityTypeHeadingResidency = {
    content: "An eligibility constraint based on residency status applies"
  };

  const tippyFeeDiscountEligibilityTypeHeadingNatural = {
    content:
      "The customer must be a natural person rather than another legal entity"
  };

  const tippyFeeDiscountEligibilityTypeHeadingIntro = {
    content: "The discount is only available during an introductory period"
  };

  const tippyFeeDiscountEligibilityTypeHeadingOther = {
    content:
      "Another eligibility criteria exists as described in the additional info field"
  };

  // ######## header tippys ########

  // -- deposits --

  const tippyDepositsHeaderRateType = {
    content: "The type of rate (base, bonus, etc)"
  };

  const tippyDepositsHeaderRate = {
    content: "The rate to be applied"
  };

  const tippyDepositsHeaderCriterias = {
    content: "Defines the criteria and conditions for which a rate applies"
  };

  const tippyDepositsHeaderRateTypeInfo = {
    content:
      "Generic field containing additional information relevant to the deposit rate type specified"
  };

  const tippyDepositsHeaderAdditionalInfo = {
    content: "Text providing more information on the rate"
  };

  // -- Lending --

  const tippyLendingHeaderRateType = {
    content: "The type of rate (fixed, variable, etc)"
  };

  const tippyLendingHeaderRate = {
    content: "The rate to be applied"
  };

  const tippyLendingHeaderComparisonRate = {
    content:
      "A comparison rate equivalent for this rate. A comparison rate includes the interest rate as well as certain fees and charges relating to a loan."
  };

  const tippyLendingHeaderCriterias = {
    content: "Defines the criteria and conditions for which a rate applies"
  };

  const tippyLendingHeaderRateTypeInfo = {
    content:
      "Generic field containing additional information relevant to the lending rate type specified"
  };

  const tippyLendingHeaderAdditionalInfo = {
    content: "Text providing more information on the rate"
  };

  // -- fees --

  const tippyFeesHeaderFeeName = {
    content: "Name of the fee"
  };

  const tippyFeesHeaderFeeType = {
    content: "The type of fee"
  };

  const tippyFeesHeaderFeeCharged = {
    content: "The fees charged for this product"
  };

  const tippyFeesHeaderTypeInfo = {
    content:
      "Generic field containing additional information relevant to the fee type specified"
  };

  const tippyFeesHeaderAdditionalInfo = {
    content: "Text providing more information on the fee"
  };

  const tippyFeesHeaderDiscountsAvailable = {
    content:
      "A list of discounts to this fee that may be available. There are also eligibility requirements to receive the discount."
  };

  // -- features --

  const tippyFeaturesHeaderFeatureType = {
    content: "The type of feature described"
  };

  const tippyFeaturesHeaderTypeInfo = {
    content:
      "Generic field containing additional information relevant to the feature type specified"
  };

  const tippyFeaturesHeaderAdditionalInfo = {
    content: "Text providing more information on the feature"
  };

  // -- eligibility --

  const tippyEligibilityHeaderEligibilityType = {
    content: "The type of eligibility criteria described"
  };

  const tippyEligibilityHeaderTypeInfo = {
    content:
      "Generic field containing additional information relevant to the eligibility type specified"
  };

  const tippyEligibilityHeaderAdditionalInfo = {
    content: "Text providing more information on the eligibility criteria"
  };

  // -- constraint --

  const tippyConstraintHeaderConstraintType = {
    content: "The type of constraint described"
  };

  const tippyConstraintHeaderTypeInfo = {
    content:
      "Generic field containing additional information relevant to the constraint type specified"
  };

  const tippyConstraintHeaderAdditionalInfo = {
    content: "Text providing more information the constraint"
  };
</script>

<style>
  /* The emerging W3C standard
   that is currently Firefox-only */
  * {
    scrollbar-width: thin;
    /* scrollbar-color: blue orange; */
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }
  *::-webkit-scrollbar-track {
    /* background: orange; */
  }

  *::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #b7b7b7;
    border-radius: 10px;
    border: 1px solid #ffffff;
    /* background-color: blue; */
    /* border-radius: 20px; */
    /* border: 3px solid orange; */
  }

  .card-effect {
    margin-bottom: 40px;
  }

  .product-title {
    font-size: 1em;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
  }

  .view-button {
    font-size: 0.825em;
  }

  .data-box {
    padding: 10px;
    border: 1px solid #a0a0a0;
  }

  .no-data-box {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .data-heading {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: rgb(93, 109, 126);
    margin-bottom: 10px;
    /* background-color: rgb(93, 109, 126); */
  }

  .no-data-message {
    color: #ffbdbd;
    font-weight: 600;
    font-size: 13px;
  }

  .tab-layout {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  /* round out tabs effect */

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    padding-left: 15px;
    z-index: 5;
  }
  ul li {
    color: grey;
    background: #fefefe;
    padding: 4px 16px 6px;
    margin: 0px -6px 0 10px;
    position: relative;
    float: left;
    text-align: center;
    z-index: 1;
  }
  ul li::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    border-style: solid;
    border-color: #a0a0a0;
    border-width: 1px 0 1px 1px;
    border-radius: 8px 0 0 0;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
    background-color: inherit;
    z-index: -1;
  }
  ul li::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    border-style: solid;
    border-color: #a0a0a0;
    border-width: 1px 1px 1px 0;
    border-radius: 0 8px 0 0;
    -webkit-transform: skewX(20deg);
    -moz-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    transform: skewX(20deg);
    background-color: inherit;
    z-index: -1;
  }

  ul li.active {
    top: 1px;
    font-size: 14px;
    font-weight: 700;
    color: rgb(93, 109, 126);
    z-index: 10;
  }

  ul li.active::before,
  ul li.active::after {
    background-color: #fff;
    border-bottom-color: #fff;
  }

  /* ul li:not([class='active']):hover::before,
  ul li:not([class='active']):hover::after {
      background-color: #efefef; 
  } */

  .apply-button-style-card {
    background-color: #3ecf8e;
    color: #fff;
    text-shadow: 0 1px 3px rgba(36, 180, 126, 0.4);
  }

  .card-footer-links {
    font-size: 0.75em;
    margin-right: 14px;
    margin-left: 14px;
  }

  /* reserve items for playing with the tables */

  .table-header {
    font-size: 11px;
    font-weight: 700;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e5e5e5;
  }

  /* Add condition of beyond a certain width, align left */

  .table-header-div {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    /* align-content: center; */
    justify-content: center;
    text-align: center;
  }

  .table-text {
    font-size: 13px;
    color: #000000;
  }

  .table-heading-row {
  }

  /* think td only ahs bottom border */

  /* make so table header doesn't change color in hover */
  .table thead tr:hover th {
    background-color: #ffffff;
  }

  .primary-content {
    /* font-size: 1.1em; */
    /* font-weight: 500; */
    /* color:#34495E; */
    /* color: rgb(93, 109, 126); */
    /* font-weight: 700; */
  }

  .primary-heading-field {
    /* font-size: 0.85em; */
    /* font-weight: 500; */
    /* color: #34495E; */
    /* color: rgb(93, 109, 126); */
    /* font-weight: 700; */
  }

  .secondary-content {
    /* font-size: 0.85em; */
    /* color: rgb(120, 120, 120); */
  }

  .table-sub-content {
    color: #898989;
    font-size: 11px;
  }

  .sub-heading-field {
    /* font-size: 0.85em; */
    /* font-weight: 500; */
    /* color: #34495E; */
    /* color: rgb(93, 109, 126); */
    /* font-weight: 700; */
  }

  .tooltip-indicator {
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  .gg-close {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    transform: scale(1);
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px;
  }

  .gg-close:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .gg-close::after,
  .gg-close::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 1px;
  }

  .gg-close::after {
    transform: rotate(-45deg);
  }

  .gg-info {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(0.8);
    width: 20px;
    height: 20px;
    border: 2px solid #b7b7b7;
    border-radius: 40px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .gg-info:hover {
    /* background:#000000; */
    border: 2px solid #000000;
  }

  .gg-info::after,
  .gg-info::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    /* background: currentColor; */
    background: #b7b7b7;
    left: 7px;
  }

  .gg-info::after {
    bottom: 2px;
    height: 8px;
  }

  .gg-info::before {
    height: 2px;
    top: 2px;
  }

  .gg-info:hover::after {
    bottom: 2px;
    height: 8px;
    background: #000000;
  }

  .gg-info:hover::before {
    height: 2px;
    top: 2px;
    background: #000000;
  }
</style>

<div class="container card-effect">

  <p class="product-title">{name}</p>

  <!-- <p>Available for tailoring: {isTailored}</p>
  <time datetime="{lastUpdated}">Product last updated: {lastUpdated}</time> -->

  <div
    class="field is-grouped is-grouped-centered is-grouped-multiline"
    style="margin-bottom:0px">

    {#if $depositsViewStore}
      <label class="click-button-active view-button">
        <input type="checkbox" hidden bind:checked={$depositsViewStore} />
        <span class="active-text">
          Deposit rates
          <span style="font-weight:600">({depositRatesRecords})</span>
        </span>
      </label>
    {:else}
      <label class="click-button view-button">
        <input
          type="checkbox"
          hidden
          bind:checked={$depositsViewStore}
          on:change={depositsDecreaseCounter}
          on:click={depositsDecreaseCounter} />
        <span>
          Deposit rates
          <span style="font-weight:600">({depositRatesRecords})</span>
        </span>
      </label>
    {/if}

    {#if $lendingsViewStore}
      <label class="click-button-active view-button">
        <input type="checkbox" hidden bind:checked={$lendingsViewStore} />
        <span class="active-text">
          Lending rates
          <span style="font-weight:600">({lendingRatesRecords})</span>
        </span>
      </label>
    {:else}
      <label class="click-button view-button">
        <input
          type="checkbox"
          hidden
          bind:checked={$lendingsViewStore}
          on:change={lendingDecreaseCounter}
          on:click={lendingDecreaseCounter} />
        <span>
          Lending rates
          <span style="font-weight:600">({lendingRatesRecords})</span>
        </span>
      </label>
    {/if}

    {#if $feesViewStore}
      <label class="click-button-active view-button">
        <input type="checkbox" hidden bind:checked={$feesViewStore} />
        <span class="active-text">
          Fees
          <span style="font-weight:600">({feesRecords})</span>
        </span>
      </label>
    {:else}
      <label class="click-button view-button">
        <input
          type="checkbox"
          hidden
          bind:checked={$feesViewStore}
          on:change={feesDecreaseCounter}
          on:click={feesDecreaseCounter} />
        <span>
          Fees
          <span style="font-weight:600">({feesRecords})</span>
        </span>
      </label>
    {/if}

    {#if $featuresViewStore}
      <label class="click-button-active view-button">
        <input type="checkbox" hidden bind:checked={$featuresViewStore} />
        <span class="active-text">
          Features
          <span style="font-weight:600">({featuresRecords})</span>
        </span>
      </label>
    {:else}
      <label class="click-button view-button">
        <input
          type="checkbox"
          hidden
          bind:checked={$featuresViewStore}
          on:change={featuresDecreaseCounter}
          on:click={featuresDecreaseCounter} />
        <span>
          Features
          <span style="font-weight:600">({featuresRecords})</span>
        </span>
      </label>
    {/if}

    {#if $eligibilityViewStore}
      <label class="click-button-active view-button">
        <input type="checkbox" hidden bind:checked={$eligibilityViewStore} />
        <span class="active-text">
          Eligibility
          <span style="font-weight:600">({eligibilityRecords})</span>
        </span>
      </label>
    {:else}
      <label class="click-button view-button">
        <input
          type="checkbox"
          hidden
          bind:checked={$eligibilityViewStore}
          on:change={eligibilityDecreaseCounter}
          on:click={eligibilityDecreaseCounter} />
        <span>
          Eligibility
          <span style="font-weight:600">({eligibilityRecords})</span>
        </span>
      </label>
    {/if}

    {#if $constraintsViewStore}
      <label class="click-button-active view-button">
        <input type="checkbox" hidden bind:checked={$constraintsViewStore} />
        <span class="active-text">
          Constraints
          <span style="font-weight:600">({constraintsRecords})</span>
        </span>
      </label>
    {:else}
      <label class="click-button view-button">
        <input
          type="checkbox"
          hidden
          bind:checked={$constraintsViewStore}
          on:change={constraintsDecreaseCounter}
          on:click={constraintsDecreaseCounter} />
        <span>
          Constraints
          <span style="font-weight:600">({constraintsRecords})</span>
        </span>
      </label>
    {/if}

    <!-- {#if $bundlesViewStore}
      <label class="click-button-active view-button">
        <input type="checkbox" hidden bind:checked={$bundlesViewStore}>
          <span class="active-text">Bundles <span style="font-weight:600">({bundlesRecords})</span></span>
      </label>
    {:else}
      <label class="click-button view-button">
        <input type="checkbox" hidden bind:checked={$bundlesViewStore} on:change={bundlesDecreaseCounter} on:click={bundlesDecreaseCounter}>
          <span>Bundles <span style="font-weight:600">({bundlesRecords})</span></span>
      </label>
    {/if} -->

  </div>

  <div id="productdetails" style="margin-bottom:20px">

    {#if $depositsViewStore}
      <div id={$depositsOrderStore} class="tab-layout">

        <ul>
          <li class="active">
            <span class="icon">
              <i
                on:click={handleDepositsClose}
                class="gg-close"
                style="top:6px; right:4px;" />
            </span>
            Deposit rates
          </li>
        </ul>

        <div class="box data-box" class:no-data-box={depositRates == undefined}>

          {#if depositRates !== undefined}
            <div class="table-container">
              <table class="table is-striped is-hoverable is-bordered">

                <thead>
                  <tr class="table-header">

                    <th>
                      <div class="table-header-div">
                        <span>Rate type</span>
                        <span>
                          <i
                            use:tippy={tippyDepositsHeaderRateType}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Rate</span>
                        <span>
                          <i
                            use:tippy={tippyDepositsHeaderRate}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Criterias</span>
                        <span>
                          <i
                            use:tippy={tippyDepositsHeaderCriterias}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Type info</span>
                        <span>
                          <i
                            use:tippy={tippyDepositsHeaderRateTypeInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Additional info</span>
                        <span>
                          <i
                            use:tippy={tippyDepositsHeaderAdditionalInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>
                    <!-- <th><abbr title="Rate w/o Percentage">Further Information</abbr></th> -->

                    <!-- <th><abbr title="Calculation Frequency">Calc Freq</abbr></th>
                  <th><abbr title="Application Frequency">App Freq</abbr></th>
                  <th><abbr title="Application Frequency">Add value</abbr></th>
                  <th><abbr title="Application Frequency">Add info</abbr></th>
                  <th><abbr title="Application Frequency">Add uri</abbr></th> -->
                  </tr>
                </thead>

                <tbody class="table-text">

                  <!-- empty row to make stripes start on first line -->
                  <tr />

                  {#each depositRates as { depositRateType, rate, calculationFrequency, applicationFrequency, additionalValue, additionalInfo, additionalInfoUri, tiers }}
                    <tr>

                      <td>

                        <div>

                          {#if depositRateType == 'FIXED'}
                            <span
                              use:tippy={tippyDepositFixed}
                              class="primary-content tooltip-indicator">
                              {depositRateType}
                            </span>
                          {:else if depositRateType == 'BONUS'}
                            <span
                              use:tippy={tippyDepositBonus}
                              class="primary-content tooltip-indicator">
                              {depositRateType}
                            </span>
                          {:else if depositRateType == 'BUNDLE_BONUS'}
                            <span
                              use:tippy={tippyDepositBundle}
                              class="primary-content tooltip-indicator">
                              {depositRateType}
                            </span>
                          {:else if depositRateType == 'VARIABLE'}
                            <span
                              use:tippy={tippyDepositVariable}
                              class="primary-content tooltip-indicator">
                              {depositRateType}
                            </span>
                          {:else if depositRateType == 'INTRODUCTORY'}
                            <span
                              use:tippy={tippyDepositIntroductory}
                              class="primary-content tooltip-indicator">
                              {depositRateType}
                            </span>
                          {:else if depositRateType == 'FLOATING'}
                            <span
                              use:tippy={tippyDepositFloating}
                              class="primary-content tooltip-indicator">
                              {depositRateType}
                            </span>
                          {:else if depositRateType == 'MARKET_LINKED'}
                            <span
                              use:tippy={tippyDepositMarket}
                              class="primary-content tooltip-indicator">
                              {depositRateType}
                            </span>
                          {:else}
                            <span class="primary-content">
                              {depositRateType}
                            </span>
                          {/if}

                        </div>

                      </td>

                      {#if tiers !== undefined}
                        <td>
                          <span class="primary-content">{rate}%</span>
                        </td>

                        <td>
                          {#each tiers as { name, unitOfMeasure, minimumValue, maximumValue, rateApplicationMethod, applicabilityConditions }}
                            <span>{name}</span>
                            <br />
                            <span class="table-sub-content">
                              {unitOfMeasure}
                            </span>
                            <br />
                            <span class="table-sub-content">
                              Min: {minimumValue}
                            </span>

                            {#if maximumValue !== undefined}
                              <br />
                              <span class="table-sub-content">
                                Max: {maximumValue}
                              </span>
                            {/if}

                            <!-- {#if rateApplicationMethod !== undefined}
                            {rateApplicationMethod}
                          {/if} -->

                            <!-- {#if applicabilityConditions !== undefined}
                            {#if applicabilityConditions.additionalInfo !== undefined}
                              <br>
                              <br>
                              {applicabilityConditions.additionalInfo}
                            {/if}            

                          {/if} -->
                          {/each}
                        </td>
                      {:else}
                        <td>
                          <span class="primary-content">{rate}%</span>
                        </td>

                        <!-- Empty td to fill space in table under criteria -->
                        <td />
                      {/if}

                      <!-- {#if calculationFrequency !== undefined}
                      <td>
                        <p>{calculationFrequency}</p>
                      </td>
                    {/if}

                    {#if applicationFrequency !== undefined}
                      <td>
                        <p>{applicationFrequency}</p>
                      </td>
                    {/if} -->

                      <td>

                        {#if additionalValue !== undefined}
                          {#if depositRateType == 'FIXED'}
                            <span>Fixed duration:</span>
                            <br />
                            <span
                              use:tippy={timePeriodsISO}
                              class="table-sub-content tooltip-indicator">
                              {additionalValue}
                            </span>
                          {:else if depositRateType == 'INTRODUCTORY'}
                            <span>Introductory duration:</span>
                            <br />
                            <span
                              use:tippy={timePeriodsISO}
                              class="table-sub-content tooltip-indicator">
                              {additionalValue}
                            </span>
                          {:else if depositRateType == 'BUNDLE'}
                            <span>Bundle:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else if depositRateType == 'FLOATING'}
                            <span>Float parameters:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else if depositRateType == 'MARKET_LINKED'}
                            <span>Market linkage:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else}
                            <span>{additionalValue}</span>
                          {/if}
                        {/if}

                      </td>

                      <td>
                        {#if additionalInfo !== undefined}
                          <span class="secondary-content">
                            {additionalInfo}
                          </span>
                        {/if}

                      </td>

                      <!-- {#if additionalInfoUri !== undefined}
                      <td>
                        <p>{additionalInfoUri}</p>
                      </td>
                    {/if} -->

                    </tr>
                  {/each}

                </tbody>

              </table>
            </div>
          {:else}
            <span class="no-data-message">No data available</span>
          {/if}

        </div>

      </div>
    {/if}

    {#if $lendingsViewStore}
      <div id={$lendingOrderStore} class="tab-layout">

        <ul>
          <li class="active">
            <span class="icon is-small">
              <i
                on:click={handleLendingsClose}
                class="gg-close"
                style="top:6px; right:4px;" />
            </span>
            Lending rates
          </li>
        </ul>

        <div class="box data-box" class:no-data-box={lendingRates == undefined}>

          {#if lendingRates !== undefined}
            <div class="table-container">
              <table class="table is-striped is-hoverable is-bordered">

                <thead>
                  <tr class="table-header">

                    <th>
                      <div class="table-header-div">
                        <span>Rate type</span>
                        <span>
                          <i
                            use:tippy={tippyLendingHeaderRateType}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Rate</span>
                        <span>
                          <i
                            use:tippy={tippyLendingHeaderRate}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Comparison rate</span>
                        <span>
                          <i
                            use:tippy={tippyLendingHeaderComparisonRate}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Criterias</span>
                        <span>
                          <i
                            use:tippy={tippyLendingHeaderCriterias}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Type info</span>
                        <span>
                          <i
                            use:tippy={tippyLendingHeaderRateTypeInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Additional info</span>
                        <span>
                          <i
                            use:tippy={tippyLendingHeaderAdditionalInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <!-- <th><abbr title="Rate w/o Percentage">Further Information</abbr></th> -->

                    <!-- <th><abbr title="Calculation Frequency">Calc Freq</abbr></th>
                  <th><abbr title="Application Frequency">App Freq</abbr></th>
                  <th><abbr title="Application Frequency">Add value</abbr></th>
                  <th><abbr title="Application Frequency">Add info</abbr></th>
                  <th><abbr title="Application Frequency">Add uri</abbr></th> -->
                  </tr>
                </thead>

                <tbody class="table-text">

                  <!-- empty row to make stripes start on first line -->
                  <tr />

                  {#each lendingRates as { lendingRateType, rate, comparisonRate, calculationFrequency, applicationFrequency, interestPaymentDue, additionalValue, additionalInfo, additionalInfoUri, tiers }}
                    <tr>

                      <td>

                        <div>

                          {#if lendingRateType == 'FIXED'}
                            <span
                              use:tippy={tippyLendingFixed}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'VARIABLE'}
                            <span
                              use:tippy={tippyLendingVariable}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'INTRODUCTORY'}
                            <span
                              use:tippy={tippyLendingIntroductory}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'DISCOUNT'}
                            <span
                              use:tippy={tippyLendingDiscount}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'PENALTY'}
                            <span
                              use:tippy={tippyLendingPenalty}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'FLOATING'}
                            <span
                              use:tippy={tippyLendingFloating}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'MARKET_LINKED'}
                            <span
                              use:tippy={tippyLendingMarket}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'CASH_ADVANCE'}
                            <span
                              use:tippy={tippyLendingCash}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'PURCHASE'}
                            <span
                              use:tippy={tippyLendingPurchase}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'BUNDLE_DISCOUNT_FIXED'}
                            <span
                              use:tippy={tippyLendingBundleFixed}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else if lendingRateType == 'BUNDLE_DISCOUNT_VARIABLE'}
                            <span
                              use:tippy={tippyLendingBundleVariable}
                              class="primary-content tooltip-indicator">
                              {lendingRateType}
                            </span>
                          {:else}
                            <span class="primary-content">
                              {lendingRateType}
                            </span>
                          {/if}

                        </div>

                      </td>

                      <td>
                        <span class="primary-content">{rate}%</span>
                      </td>

                      <td>

                        {#if comparisonRate !== undefined}
                          <span class="primary-content">{comparisonRate}%</span>
                        {/if}

                      </td>

                      {#if tiers !== undefined}
                        <td>
                          {#each tiers as { name, unitOfMeasure, minimumValue, maximumValue, rateApplicationMethod, applicabilityConditions }}
                            <span>{name}</span>
                            <br />
                            <span class="table-sub-content">
                              {unitOfMeasure}
                            </span>
                            <br />
                            <span class="table-sub-content">
                              Min: {minimumValue}
                            </span>

                            {#if maximumValue !== undefined}
                              <br />
                              <span class="table-sub-content">
                                Max: {maximumValue}
                              </span>
                            {/if}

                            <!-- {#if rateApplicationMethod !== undefined}
                            {rateApplicationMethod}
                          {/if} -->

                            <!-- {#if applicabilityConditions !== undefined}
                            {#if applicabilityConditions.additionalInfo !== undefined}
                              <br>
                              <br>
                              {applicabilityConditions.additionalInfo}
                            {/if}            

                          {/if} -->
                          {/each}
                        </td>
                      {:else}
                        <!-- Empty td to fill space in table under criteria -->
                        <td />
                      {/if}

                      <!-- {#if calculationFrequency !== undefined}
                      <td>
                        <p>{calculationFrequency}</p>
                      </td>
                    {/if}

                    {#if applicationFrequency !== undefined}
                      <td>
                        <p>{applicationFrequency}</p>
                      </td>
                    {/if} -->
                      <td>
                        {#if additionalValue !== undefined}
                          {#if lendingRateType == 'FIXED'}
                            <span>Fixed duration:</span>
                            <br />
                            <span
                              use:tippy={timePeriodsISO}
                              class="table-sub-content tooltip-indicator">
                              {additionalValue}
                            </span>
                          {:else if lendingRateType == 'INTRODUCTORY'}
                            <span>Introductory duration:</span>
                            <br />
                            <span
                              use:tippy={timePeriodsISO}
                              class="table-sub-content tooltip-indicator">
                              {additionalValue}
                            </span>
                          {:else if lendingRateType == 'DISCOUNT'}
                            <span>Discount description:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else if lendingRateType == 'PENALTY'}
                            <span>Penalty description:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else if lendingRateType == 'FLOATING'}
                            <span>Float parameters:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else if lendingRateType == 'MARKET_LINKED'}
                            <span>Market linkage:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else if lendingRateType == 'BUNDLE_DISCOUNT_FIXED'}
                            <span>Bundle:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else if lendingRateType == 'BUNDLE_DISCOUNT_VARIABLE'}
                            <span>Bundle:</span>
                            <br />
                            <span class="table-sub-content">
                              {additionalValue}
                            </span>
                          {:else}
                            <span>{additionalValue}</span>
                          {/if}
                        {/if}
                      </td>

                      <td>
                        {#if additionalInfo !== undefined}
                          <span class="secondary-content">
                            {additionalInfo}
                          </span>
                        {/if}
                      </td>

                      <!-- {#if additionalInfoUri !== undefined}
                      <td>
                        <p>{additionalInfoUri}</p>
                      </td>
                    {/if} -->

                    </tr>
                  {/each}

                </tbody>

              </table>
            </div>
          {:else}
            <span class="no-data-message">No data available</span>
          {/if}

        </div>

      </div>
    {/if}

    {#if $feesViewStore}
      <div id={$feesOrderStore} class="tab-layout">

        <ul>
          <li class="active">
            <span class="icon is-small">
              <i
                on:click={handleFeesClose}
                class="gg-close"
                style="top:6px; right:4px;" />
            </span>
            Fees
          </li>
        </ul>

        <div class="box data-box" class:no-data-box={fees == undefined}>

          {#if fees !== undefined}
            <div class="table-container">
              <table class="table is-striped is-hoverable is-bordered">

                <thead>
                  <tr class="table-header">

                    <th>
                      <div class="table-header-div">
                        <span>Fee name</span>
                        <span>
                          <i
                            use:tippy={tippyFeesHeaderFeeName}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Fee type</span>
                        <span>
                          <i
                            use:tippy={tippyFeesHeaderFeeType}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Fee charged</span>
                        <span>
                          <i
                            use:tippy={tippyFeesHeaderFeeCharged}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Type info</span>
                        <span>
                          <i
                            use:tippy={tippyFeesHeaderTypeInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Additional info</span>
                        <span>
                          <i
                            use:tippy={tippyFeesHeaderAdditionalInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Discounts available</span>
                        <span>
                          <i
                            use:tippy={tippyFeesHeaderDiscountsAvailable}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                  </tr>
                </thead>

                <tbody class="table-text">

                  <!-- empty row to make stripes start on first line -->
                  <tr />

                  {#each fees as { name, feeType, amount, balanceRate, transactionRate, accruedRate, accrualFrequency, additionalValue, additionalInfo, discounts }}
                    <tr>

                      <td>
                        <span class="primary-content">{name}</span>
                      </td>

                      <td>

                        <div>

                          {#if feeType == 'PERIODIC'}
                            <span
                              use:tippy={tippyFeePeriodic}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'TRANSACTION'}
                            <span
                              use:tippy={tippyFeeTransaction}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'WITHDRAWAL'}
                            <span
                              use:tippy={tippyFeeWithdrawal}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'DEPOSIT'}
                            <span
                              use:tippy={tippyFeeDeposit}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'PAYMENT'}
                            <span
                              use:tippy={tippyFeePayment}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'PURCHASE'}
                            <span
                              use:tippy={tippyFeePurchase}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'EVENT'}
                            <span
                              use:tippy={tippyFeeEvent}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'UPFRONT'}
                            <span
                              use:tippy={tippyFeeUpfront}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else if feeType == 'EXIT'}
                            <span
                              use:tippy={tippyFeeExit}
                              class="primary-content tooltip-indicator">
                              {feeType}
                            </span>
                          {:else}
                            <span class="primary-content">{feeType}</span>
                          {/if}

                        </div>

                      </td>

                      <td>

                        <!-- add null check for NAB -->

                        {#if amount !== undefined && amount !== null}
                          <span
                            class="tooltip-indicator"
                            use:tippy={tippyFeeHeadingAmount}>
                            Amount:
                          </span>
                          <span class="table-sub-content">${amount}</span>
                        {/if}

                        {#if balanceRate !== undefined && balanceRate !== null}
                          <span
                            class="tooltip-indicator"
                            use:tippy={tippyFeeHeadingBalance}>
                            Balance rate:
                          </span>
                          <span class="table-sub-content">{balanceRate}%</span>
                        {/if}

                        {#if transactionRate !== undefined && transactionRate !== null}
                          <span
                            class="tooltip-indicator"
                            use:tippy={tippyFeeHeadingTransaction}>
                            Transaction rate:
                          </span>
                          <span class="table-sub-content">
                            {transactionRate}%
                          </span>
                        {/if}

                        {#if accruedRate !== undefined && accruedRate !== null}
                          <span
                            class="tooltip-indicator"
                            use:tippy={tippyFeeHeadingAccrued}>
                            Accrued rate:
                          </span>
                          <span class="table-sub-content">{accruedRate}%</span>
                        {/if}

                        {#if accrualFrequency !== undefined}
                          <span
                            class="tooltip-indicator"
                            use:tippy={tippyFeeHeadingAccrual}>
                            Accrual frequency
                          </span>
                          <span class="table-sub-content">
                            {accrualFrequency}
                          </span>
                        {/if}

                      </td>

                      <td>
                        {#if additionalValue !== undefined}
                          {#if feeType == 'PERIODIC'}
                            <span>Periodic fee:</span>
                            <br />
                            <span
                              use:tippy={timePeriodsISO}
                              class="tooltip-indicator">
                              {additionalValue}
                            </span>
                          {:else}
                            <span>{additionalValue}</span>
                          {/if}
                        {/if}
                      </td>

                      <td>
                        {#if additionalInfo !== undefined}
                          <span class="secondary-content">
                            {additionalInfo}
                          </span>
                        {/if}
                      </td>

                      <td>
                        {#if discounts !== undefined}
                          {#each discounts as { description, discountType, amount, balanceRate, transactionRate, accruedRate, feeRate, additionalValue, additionalInfo, eligibility }}
                            <span>{description}</span>
                            <br />
                            {#if discountType == 'BALANCE'}
                              <span
                                use:tippy={tippyFeeDiscountTypeHeadingBalance}
                                class="table-sub-content tooltip-indicator">
                                {discountType}
                              </span>
                            {:else if discountType == 'DEPOSITS'}
                              <span
                                use:tippy={tippyFeeDiscountTypeHeadingDeposits}
                                class="table-sub-content tooltip-indicator">
                                {discountType}
                              </span>
                            {:else if discountType == 'PAYMENTS'}
                              <span
                                use:tippy={tippyFeeDiscountTypeHeadingPayments}
                                class="table-sub-content tooltip-indicator">
                                {discountType}
                              </span>
                            {:else if discountType == 'FEE_CAP'}
                              <span
                                use:tippy={tippyFeeDiscountTypeHeadingFee}
                                class="table-sub-content tooltip-indicator">
                                {discountType}
                              </span>
                            {:else if discountType == 'ELIGIBILITY_ONLY'}
                              <span
                                use:tippy={tippyFeeDiscountTypeHeadingEligibility}
                                class="table-sub-content tooltip-indicator">
                                {discountType}
                              </span>
                            {:else}
                              <span class="table-sub-content">
                                {discountType}
                              </span>
                            {/if}

                            <!-- add null check for NAB -->

                            {#if amount !== undefined && amount !== null}
                              <br />
                              <span class="table-sub-content">
                                Discount amount: ${amount}
                              </span>
                            {/if}

                            {#if balanceRate !== undefined && balanceRate !== null}
                              <br />
                              <span class="table-sub-content">
                                Discount balance rate: {balanceRate}%
                              </span>
                            {/if}

                            {#if transactionRate !== undefined && transactionRate !== null}
                              <br />
                              <span class="table-sub-content">
                                Discount transaction rate: {transactionRate}%
                              </span>
                            {/if}

                            {#if accruedRate !== undefined && accruedRate !== null}
                              <br />
                              <span class="table-sub-content">
                                Discount accrued rate: {accruedRate}%
                              </span>
                            {/if}

                            {#if feeRate !== undefined && feeRate !== null}
                              <br />
                              <span class="table-sub-content">
                                Discount fee rate: {feeRate}%
                              </span>
                            {/if}

                            <!-- {#if additionalValue !== undefined}
                            <td>
                              
                              {#if discountType == "FEE_CAP"}
                                <span class="secondary-content">Duration:</span>
                                <br>
                                <span use:tippy={timePeriodsISO} class="secondary-content tooltip-indicator">{additionalValue}</span>
                              {:else}
                                <span class="secondary-content">{additionalValue}</span>
                              {/if}
                              
                            </td>
                          {/if} -->

                            {#if additionalInfo !== undefined}
                              <br />
                              <span class="table-sub-content">
                                {additionalInfo}
                              </span>
                            {/if}

                            {#if eligibility !== undefined}
                              {#each eligibility as { discountEligibilityType, additionalValue, additionalInfo }}
                                <br />
                                <span class="table-sub-content">
                                  Eligibility:
                                </span>
                                <br />

                                {#if discountEligibilityType == 'BUSINESS'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingBusiness}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'PENSION_RECIPIENT'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingPension}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'MIN_AGE'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingMinAge}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'MAX_AGE'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingMaxAge}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'MIN_INCOME'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingMinIncome}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'MIN_TURNOVER'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingMinTurnover}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'STAFF'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingStaff}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'STUDENT'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingStudent}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'EMPLOYMENT_STATUS'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingEmployment}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'RESIDENCY_STATUS'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingResidency}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'NATURAL_PERSON'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingNatural}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'INTRODUCTORY'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingIntro}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else if discountEligibilityType == 'OTHER'}
                                  <span
                                    use:tippy={tippyFeeDiscountEligibilityTypeHeadingOther}
                                    class="table-sub-content tooltip-indicator">
                                    {discountEligibilityType}
                                  </span>
                                {:else}
                                  <span class="table-sub-content">
                                    {discountEligibilityType}
                                  </span>
                                {/if}

                                {#if additionalInfo !== undefined}
                                  <br />
                                  <span class="table-sub-content">
                                    {additionalInfo}
                                  </span>
                                {/if}

                                <br />
                                <br />
                              {/each}
                            {/if}
                          {/each}
                        {/if}
                      </td>
                    </tr>
                  {/each}

                </tbody>

              </table>
            </div>
          {:else}
            <span class="no-data-message">No data available</span>
          {/if}

        </div>

      </div>
    {/if}

    {#if $featuresViewStore}
      <div id={$featuresOrderStore} class="tab-layout">

        <ul>
          <li class="active">
            <span class="icon is-small">
              <i
                on:click={handleFeaturesClose}
                class="gg-close"
                style="top:6px; right:4px;" />
            </span>
            Features
          </li>
        </ul>

        <div class="box data-box" class:no-data-box={features == undefined}>

          {#if features !== undefined}
            <div class="table-container">
              <table class="table is-striped is-hoverable is-bordered">

                <thead>
                  <tr class="table-header">

                    <th>
                      <div class="table-header-div">
                        <span>Feature type</span>
                        <span>
                          <i
                            use:tippy={tippyFeaturesHeaderFeatureType}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Type info</span>
                        <span>
                          <i
                            use:tippy={tippyFeaturesHeaderTypeInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Additional info</span>
                        <span>
                          <i
                            use:tippy={tippyFeaturesHeaderAdditionalInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                  </tr>
                </thead>

                <tbody class="table-text">

                  <!-- empty row to make stripes start on first line -->
                  <tr />

                  {#each features as { featureType, additionalValue, additionalInfo, additionalInfoUri }}
                    <tr>

                      <td>

                        <div>

                          {#if featureType == 'CARD_ACCESS'}
                            <span
                              use:tippy={tippyFeatureCard}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'ADDITIONAL_CARDS'}
                            <span
                              use:tippy={tippyFeatureAdditional}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'UNLIMITED_TXNS'}
                            <span
                              use:tippy={tippyFeatureUnlimted}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'FREE_TXNS'}
                            <span
                              use:tippy={tippyFeatureFreeTxns}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'FREE_TXNS_ALLOWANCE'}
                            <span
                              use:tippy={tippyFeatureFreeTxnsAllowance}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'LOYALTY_PROGRAM'}
                            <span
                              use:tippy={tippyFeatureLoyalty}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'OFFSET'}
                            <span
                              use:tippy={tippyFeatureOffset}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'OVERDRAFT'}
                            <span
                              use:tippy={tippyFeatureOverdraft}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'REDRAW'}
                            <span
                              use:tippy={tippyFeatureRedraw}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'INSURANCE'}
                            <span
                              use:tippy={tippyFeatureInsurance}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'BALANCE_TRANSFERS'}
                            <span
                              use:tippy={tippyFeatureBalance}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'INTEREST_FREE'}
                            <span
                              use:tippy={tippyFeatureInterestFree}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'INTEREST_FREE_TRANSFERS'}
                            <span
                              use:tippy={tippyFeatureInterestFreeTransfers}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'DIGITAL_WALLET'}
                            <span
                              use:tippy={tippyFeatureDigitalWallet}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'DIGITAL_BANKING'}
                            <span
                              use:tippy={tippyFeatureDigitalBanking}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'NPP_PAYID'}
                            <span
                              use:tippy={tippyFeatureNppPay}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'NPP_ENABLED'}
                            <span
                              use:tippy={tippyFeatureNppEnabled}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'DONATE_INTEREST'}
                            <span
                              use:tippy={tippyFeatureDonate}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'BILL_PAYMENT'}
                            <span
                              use:tippy={tippyFeatureBill}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'COMPLEMENTARY_PRODUCT_DISCOUNTS'}
                            <span
                              use:tippy={tippyFeatureComplementary}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'BONUS_REWARDS'}
                            <span
                              use:tippy={tippyFeatureBonus}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'NOTIFICATIONS'}
                            <span
                              use:tippy={tippyFeatureNotifications}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else if featureType == 'OTHER'}
                            <span
                              use:tippy={tippyFeatureOther}
                              class="primary-content tooltip-indicator">
                              {featureType}
                            </span>
                          {:else}
                            <span class="primary-content">{featureType}</span>
                          {/if}

                        </div>

                      </td>

                      <td>
                        {#if additionalValue !== undefined}
                          <span class="primary-content">{additionalValue}</span>
                        {/if}
                      </td>

                      <td>
                        {#if additionalInfo !== undefined}
                          <span class="secondary-content">
                            {additionalInfo}
                          </span>
                        {/if}
                      </td>

                    </tr>
                  {/each}

                </tbody>

              </table>
            </div>
          {:else}
            <span class="no-data-message">No data available</span>
          {/if}

        </div>

      </div>
    {/if}

    {#if $eligibilityViewStore}
      <div id={$eligibilityOrderStore} class="tab-layout">

        <ul>
          <li class="active">
            <span class="icon is-small">
              <i
                on:click={handleEligibilityClose}
                class="gg-close"
                style="top:6px; right:4px;" />
            </span>
            Eligibility
          </li>
        </ul>

        <div class="box data-box" class:no-data-box={eligibility == undefined}>

          {#if eligibility !== undefined}
            <div class="table-container">
              <table class="table is-striped is-hoverable is-bordered">

                <thead>
                  <tr class="table-header">

                    <th>
                      <div class="table-header-div">
                        <span>Eligibility type</span>
                        <span>
                          <i
                            use:tippy={tippyEligibilityHeaderEligibilityType}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Type info</span>
                        <span>
                          <i
                            use:tippy={tippyEligibilityHeaderTypeInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Additional info</span>
                        <span>
                          <i
                            use:tippy={tippyEligibilityHeaderAdditionalInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                  </tr>
                </thead>

                <tbody class="table-text">

                  <!-- empty row to make stripes start on first line -->
                  <tr />

                  {#each eligibility as { eligibilityType, additionalValue, additionalInfo, additionalInfoUri }}
                    <tr>

                      <td>

                        <div>

                          {#if eligibilityType == 'BUSINESS'}
                            <span
                              use:tippy={tippyEligibilityBusiness}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'PENSION_RECIPIENT'}
                            <span
                              use:tippy={tippyEligibilityPension}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'MIN_AGE'}
                            <span
                              use:tippy={tippyEligibilityMinAge}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'MAX_AGE'}
                            <span
                              use:tippy={tippyEligibilityMaxAge}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'MIN_INCOME'}
                            <span
                              use:tippy={tippyEligibilityMinIncome}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'MIN_TURNOVER'}
                            <span
                              use:tippy={tippyEligibilityMinTurnover}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'STAFF'}
                            <span
                              use:tippy={tippyEligibilityStaff}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'STUDENT'}
                            <span
                              use:tippy={tippyEligibilityStudent}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'EMPLOYMENT_STATUS'}
                            <span
                              use:tippy={tippyEligibilityEmployment}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'RESIDENCY_STATUS'}
                            <span
                              use:tippy={tippyEligibilityResidency}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'NATURAL_PERSON'}
                            <span
                              use:tippy={tippyEligibilityNatural}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else if eligibilityType == 'OTHER'}
                            <span
                              use:tippy={tippyEligibilityOther}
                              class="primary-content tooltip-indicator">
                              {eligibilityType}
                            </span>
                          {:else}
                            <span class="primary-content">
                              {eligibilityType}
                            </span>
                          {/if}

                        </div>

                      </td>

                      <td>
                        {#if additionalValue !== undefined}
                          <span class="primary-content">{additionalValue}</span>
                        {/if}
                      </td>

                      <td>
                        {#if additionalInfo !== undefined}
                          <span class="secondary-content">
                            {additionalInfo}
                          </span>
                        {/if}
                      </td>

                    </tr>
                  {/each}

                </tbody>

              </table>
            </div>
          {:else}
            <span class="no-data-message">No data available</span>
          {/if}

        </div>

      </div>
    {/if}

    {#if $constraintsViewStore}
      <div id={$constraintsOrderStore} class="tab-layout">

        <ul>
          <li class="active">
            <span class="icon is-small">
              <i
                on:click={handleConstraintsClose}
                class="gg-close"
                style="top:6px; right:4px;" />
            </span>
            Constraints
          </li>
        </ul>

        <div class="box data-box" class:no-data-box={constraints == undefined}>

          {#if constraints !== undefined}
            <div class="table-container">
              <table class="table is-striped is-hoverable is-bordered">

                <thead>
                  <tr class="table-header">

                    <th>
                      <div class="table-header-div">
                        <span>Constraint type</span>
                        <span>
                          <i
                            use:tippy={tippyConstraintHeaderConstraintType}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Type info</span>
                        <span>
                          <i
                            use:tippy={tippyConstraintHeaderTypeInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                    <th>
                      <div class="table-header-div">
                        <span>Additional info</span>
                        <span>
                          <i
                            use:tippy={tippyConstraintHeaderAdditionalInfo}
                            class="gg-info" />
                        </span>
                      </div>
                    </th>

                  </tr>
                </thead>

                <tbody class="table-text">

                  <!-- empty row to make stripes start on first line -->
                  <tr />

                  {#each constraints as { constraintType, additionalValue, additionalInfo, additionalInfoUri }}
                    <tr>

                      <td>

                        <div>

                          {#if constraintType == 'MIN_BALANCE'}
                            <span
                              use:tippy={tippyConstraintMinBalance}
                              class="primary-content tooltip-indicator">
                              {constraintType}
                            </span>
                          {:else if constraintType == 'MAX_BALANCE'}
                            <span
                              use:tippy={tippyConstraintMaxBalance}
                              class="primary-content tooltip-indicator">
                              {constraintType}
                            </span>
                          {:else if constraintType == 'OPENING_BALANCE'}
                            <span
                              use:tippy={tippyConstraintOpening}
                              class="primary-content tooltip-indicator">
                              {constraintType}
                            </span>
                          {:else if constraintType == 'MAX_LIMIT'}
                            <span
                              use:tippy={tippyConstraintMinLimit}
                              class="primary-content tooltip-indicator">
                              {constraintType}
                            </span>
                          {:else if constraintType == 'MIN_LIMIT'}
                            <span
                              use:tippy={tippyConstraintMaxLimit}
                              class="primary-content tooltip-indicator">
                              {constraintType}
                            </span>
                          {:else}
                            <span class="primary-content">
                              {constraintType}
                            </span>
                          {/if}

                        </div>

                      </td>

                      <td>
                        {#if additionalValue !== undefined}
                          <span class="primary-content">
                            ${additionalValue}
                          </span>
                        {/if}
                      </td>

                      <td>
                        {#if additionalInfo !== undefined}
                          <span class="secondary-content">
                            {additionalInfo}
                          </span>
                        {/if}
                      </td>

                    </tr>
                  {/each}

                </tbody>

              </table>
            </div>
          {:else}
            <span class="no-data-message">No data available</span>
          {/if}

        </div>

      </div>
    {/if}

    <!-- {#if $bundlesViewStore}

      <div id={$bundlesOrderStore} class="box data-box" class:no-data-box={bundles == undefined}>

        {#if bundles !== undefined}

          <h6 class="data-heading">Bundles</h6>

          <div class="table-container">
            <table class="table">
              
              <thead>
                <tr class="table-header">
                  <th><abbr title="Bundles Type">Type</abbr></th>
                </tr>
              </thead>

              <tbody class="table-text">

                {#each bundles as { name, description, additionalInfo, additionalInfoUri, productIds }}

                  <tr>

                    <td>
                      <span>{name}</span>
                    </td>

                    <td>
                      <span>{description}</span>
                    </td>
                    

                    {#if additionalInfo !== undefined}
                      <td>
                        <span>{additionalInfo}</span>
                      </td>
                    {/if}

                    {#if additionalInfoUri !== undefined}
                      <td>
                        <span>{additionalInfoUri}</span>
                      </td>
                    {/if}

                    {#each productIds as {string}}
                      <span>{string}</span>
                    {/each}

                  </tr>

                {/each}

              </tbody>

            </table>
          </div>

        {:else}

        <span class="no-data-message">No data available</span>

        {/if}

      </div>

    {/if} -->

  </div>

  {#if applicationUri !== undefined}
    <button
      onClick="window.open('{applicationUri}');"
      class="button is-rounded apply-button-style-card"
      style="margin-bottom: 14px">
      Apply
    </button>
  {/if}

  <!-- Make this small and convert into human format-->

  <!-- {#if effectiveFrom !== undefined}
    <time datetime="{effectiveFrom}">Product effective from: {effectiveFrom}</time>
  {/if}

  {#if effectiveTo !== undefined}
    <time datetime="{effectiveTo}">Product effective to: {effectiveTo}</time>
  {/if} -->

  {#if additionalInformation !== undefined}
    <div class="columns is-centered is-gapless">

      {#if overviewUri !== undefined}
        <a class="card-footer-links" href={overviewUri} target="_blank">
          Overview
        </a>
        <!-- <button onClick="window.open('{overviewUri}');" class="button is-rounded is-light is-small">Overview</button>-->
      {/if}

      {#if feesAndPricingUri !== undefined}
        <a class="card-footer-links" href={feesAndPricingUri} target="_blank">
          Fees and Pricing
        </a>
        <!-- <button onClick="window.open('{feesAndPricingUri}');" class="button is-rounded is-light is-small">Fees and Pricing</button> -->
      {/if}

      {#if eligibilityUri !== undefined}
        <a class="card-footer-links" href={eligibilityUri} target="_blank">
          Eligibility
        </a>
        <!-- <button onClick="window.open('{eligibilityUri}');" class="button is-rounded is-light is-small">Eligibility</button> -->
      {/if}

      {#if termsUri !== undefined}
        <a class="card-footer-links" href={termsUri} target="_blank">Terms</a>
        <!-- <button onClick="window.open('{termsUri}');" class="button is-rounded is-light is-small">Terms</button> -->
      {/if}

      {#if bundleUri !== undefined}
        <a class="card-footer-links" href={bundleUri} target="_blank">
          Bundles
        </a>
        <!-- <button onClick="window.open('{bundleUri}');" class="button is-rounded is-light is-small">Bundles</button> -->
      {/if}

    </div>
  {/if}

</div>

<script>

	import { slide } from 'svelte/transition';
	

//default select 1?

// Apply missing - unfortunately 
// suggest getting in contact with the relevant bank and asking about the specific product.
// A link to an application web page where this product can be applied for.

	
	let faqVisible = true;
	let sections = [
		{
			id: 1,
			title: "Missing apply link",
			content: "Unfortunately if the apply link is missing, it indicates that the involved bank did not provide a direct link to apply for that specific product. We suggest you contact the bank directly if you would like to apply for that product.",
			active: false,
		},
		{
			id: 2,
			title: "Update frequency",
			content: "The dataset is updated daily. We are currently working on enabling real time updates from the bank's servers. You can find out more about this <a href=\"https://github.com/ConsumerDataStandardsAustralia/standards-maintenance/issues/195\" target=\"_blank\">here</a>.",
			active: false,
		},		
		{
			id: 3,
			title: "Incomplete data",
			content: "<p>The <a href=\"https://www.accc.gov.au/focus-areas/consumer-data-right-cdr-0\" target=\"_blank\">Open Banking</a> project is currently under implementation with constant developments. While the datasets on initial release may be partial, as the banks continually progress with Open Banking, the datasets will hopefully grow to completion.</p><p>On our end, to accommodate with the set of incomplete data, we are continuously updating the user interface to show you the most complete and relevant data available. If you have any feedback relating to this, please send us an email at <a href=\"mailto:john.trieu.nguyen@gmail.com?subject=User Interface Feedback\">john.trieu.nguyen@gmail.com</a>.</p>",
			active: false,
		},
		{
			id: 4,
			title: "Other products",
			content: "<p>Products scheduled to be released this year include:</p><ol><li>business finance</li><li>loan for an investment</li><li>line of credit (both personal & business)</li><li>overdraft (both personal & business)</li><li>asset finance (including leases)</li><li>cash management account</li><li>farm management account</li><li>pensioner deeming account</li><li>retirement savings account</li><li>trust account</li><li>foreign currency account</li><li>consumer lease</li></ol><p>As soon as the products are made available, the site will be updated accordingly.</p>",
			active: false,
		},		
		{
			id: 5,
			title: "More banks",
			content: "<p>Other banks and selected Authorised Deposit-taking Institutions (ADI) are obligated to follow shortly. The complete timeline of their obligations can be viewed <a href=\"https://www.accc.gov.au/system/files/CDR%20-%20Proposed%20timetable%20for%20participation%20of%20non-major%20ADIs%20in%20the%20CDR_0.pdf\" target=\"_blank\">here</a>.</p><p>As each entity releases their product data, we will update the site to make that data available.</p>",
			active: false,
		},
		{
			id: 6,
			title: "Missing feature",
			content: "We are currently working on the following: <ol><li>Compare products</li><li>Search, sort & filter</li><li>Export data</li></ol> If the feature you are searching for was not mentioned above, send us an email at <a href=\"mailto:john.trieu.nguyen@gmail.com?subject=Feature Request\">john.trieu.nguyen@gmail.com</a> and we will see what we can do.",
			active: false,
		}
	]



	const expand = (section) => {
		sections = sections.map(s => {

			if (s.active == true && s.id === section.id) {
				s.active = false
			} else if (s.active == false && s.id === section.id) {
				s.active = true
			}
			
			return s
		
		})
	}

</script>

<style>

	.accordion-top-box {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}

	.accordion-bottom-box {
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
	}	
	
	.accordion-title {
		background-color: rgb(246, 246, 246);		
		text-align: left;
		font-weight: 600;
		font-size:14px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 20px;
		border: solid 1px rgb(246, 246, 246);
	}

	.accordion-title:hover {
		background-color: rgb(230, 230, 230);
	}	

	.accordion-bottom-border {
		border-bottom: solid 1px #b6b6b6;
	}	
		
	
	.slider {
		/* Comment out the border and padding and let the wonkyness begin!
		border: 1px solid #eee;
		padding: 4px 20px;
		*/
		
		/* Added some color to illustrate the issue */
		font-size:13px;
		/* background-color: rgb(246, 246, 246); */
		border-left: solid 1px rgb(200, 200, 200);
		border-right: solid 1px rgb(200, 200, 200);
		border-bottom: solid 1px rgb(200, 200, 200);
		margin-left:2px;
		margin-right:2px;
	}

	.icon-adjust-spacing {
		margin-top:2px;
		margin-right: 8px;		
	}

	
	.gg-chevron-down {
		color: #000000;
		box-sizing: border-box;
		position: relative;
		display: block;
		transform: scale(var(--ggs,1));
		width: 22px;
		height: 22px;
		border: 2px solid transparent;
		border-radius: 100px
	}

	.gg-chevron-down::after {
		content: "";
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 10px;
		height: 10px;
		border-bottom: 2px solid;
		border-right: 2px solid;
		transform: rotate(45deg);
		left: 4px;
		top: 2px
	} 


	.gg-chevron-up {
		color: #000000;
		box-sizing: border-box;
		position: relative;
		display: block;
		transform: scale(var(--ggs,1));
		width: 22px;
		height: 22px;
		border: 2px solid transparent;
		border-radius: 100px
	}
	.gg-chevron-up::after {
		content: "";
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 10px;
		height: 10px;
		border-top: 2px solid;
		border-right: 2px solid;
		transform: rotate(-45deg);
		left: 4px;
		bottom: 2px
	}	

</style>

<h5 class="title is-5" style="padding-left:20px; padding-right:20px;">Frequently Asked Questions</h5>


<!-- {#each sections as section} -->

<div class="container" style="width:95%; margin-bottom:40px">


	<div class="control accordion-title has-icons-right accordion-top-box" class:accordion-bottom-border={sections[0].active == false} on:click={() => expand(sections[0]) }>
	
		<span>{sections[0].title}</span>
		{#if sections[0].active}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-up" aria-hidden="true"></i>
			</span>
		{:else}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-down" aria-hidden="true"></i>
			</span>
		{/if} 

	</div>

	{#if sections[0].active}
		<div class="slider" transition:slide>
			<p style="text-align:left; padding:10px">{@html sections[0].content}</p>
		</div>
	{/if}




	<div class="control accordion-title has-icons-right" class:accordion-bottom-border={sections[1].active == false} on:click={() => expand(sections[1]) }>
	
		{sections[1].title}
		{#if sections[1].active}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-up" aria-hidden="true"></i>
			</span>
		{:else}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-down" aria-hidden="true"></i>
			</span>
		{/if} 

	</div>

	{#if sections[1].active}
		<div class="slider" transition:slide>
			<p style="text-align:left; padding:10px">{@html sections[1].content}</p>
		</div>
	{/if}




	<div class="control accordion-title has-icons-right" class:accordion-bottom-border={sections[2].active == false} on:click={() => expand(sections[2]) }>
	
		{sections[2].title}
		{#if sections[2].active}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-up" aria-hidden="true"></i>
			</span>
		{:else}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-down" aria-hidden="true"></i>
			</span>
		{/if} 

	</div>

	{#if sections[2].active}
		<div class="slider" transition:slide>
			<p style="text-align:left; padding:10px">{@html sections[2].content}</p>
		</div>
	{/if}


	<div class="control accordion-title has-icons-right" class:accordion-bottom-border={sections[3].active == false} on:click={() => expand(sections[3]) }>
	
		{sections[3].title}
		{#if sections[3].active}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-up" aria-hidden="true"></i>
			</span>
		{:else}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-down" aria-hidden="true"></i>
			</span>
		{/if} 

	</div>

	{#if sections[3].active}
		<div class="slider" transition:slide>
			<p style="text-align:left; padding:10px">{@html sections[3].content}</p>
		</div>
	{/if}


	<div class="control accordion-title has-icons-right" class:accordion-bottom-border={sections[4].active == false} on:click={() => expand(sections[4]) }>
	
		{sections[4].title}
		{#if sections[4].active}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-up" aria-hidden="true"></i>
			</span>
		{:else}
			<span class="icon is-right icon-adjust-spacing">
				<i class="gg-chevron-down" aria-hidden="true"></i>
			</span>
		{/if} 

	</div>

	{#if sections[4].active}
		<div class="slider" transition:slide>
			<p style="text-align:left; padding:10px">{@html sections[4].content}</p>
		</div>
	{/if}





	<div class="accordion-bottom-box">
		
		<div class="control accordion-title has-icons-right" class:accordion-bottom-box={sections[5].active == false} on:click={() => expand(sections[5]) }>
		
			{sections[5].title}
			{#if sections[5].active}
				<span class="icon is-right icon-adjust-spacing">
					<i class="gg-chevron-up" aria-hidden="true"></i>
				</span>
			{:else}
				<span class="icon is-right icon-adjust-spacing">
					<i class="gg-chevron-down" aria-hidden="true"></i>
				</span>
			{/if}

		</div>

		{#if sections[5].active}
			<div class="slider accordion-bottom-box" transition:slide>
				<p style="text-align:left; padding:10px">{@html sections[5].content}</p>
			</div>
		{/if}

	</div>



	<!-- <div class="control accordion-title has-icons-right" on:click={() => expand(section) }>
	
		{section.title}
		{#if section.active}
			<span class="icon is-right">
				<i class="gg-chevron-up" aria-hidden="true"></i>
			</span>
		{:else}
			<span class="icon is-right">
				<i class="gg-chevron-down" aria-hidden="true"></i>
			</span>
		{/if} 

	</div>

	{#if section.active}
		<div class="slider" transition:slide>
			<p style="text-align:left; padding:20px">{@html section.content}</p>
		</div>
	{/if} -->

</div>
<!-- {/each} -->
<script>
    import { getContext } from 'svelte';
    import Card from './Card.svelte';
	const { open } = getContext('simple-modal');
	
	import scrollLock from 'scroll-lock';

	// Stuff that will pull from App.svelte
	export let productIdTransfer
	export let hostFlag
	// Stuff that will pull from App.svelte
	 
	import { hosts,
	endPoint,
	myHeaders,
	pageSize,
	effective,
	nameStore,
	descriptionStore,
	isTailoredStore,
	lendingRatesStore,
	depositRatesStore,
	feesStore,
	featuresStore,
	eligibilityStore,
	constraintsStore,
	bundlesStore,
	additionalInformationStore,
	applicationUriStore,
	effectiveFromStore,
	effectiveToStore,
	lastUpdatedStore,
	depositRatesRecordsStore,
	lendingRatesRecordsStore,
	feesRecordsStore,
	featuresRecordsStore,
	eligibilityRecordsStore,
	constraintsRecordsStore,
	bundlesRecordsStore	
	} from './stores.js'


	var productDetailData

	// flag status for opened or closed of modal
	// let opening = false;
	// let opened = false;
	// let closing = false;
	// let closed = false;

    const showCard = () => {

		// console.log(hostFlag)

		// var hostURL

		// if (hostFlag == 'anz') {
		// 	hostURL = hosts["anz"]
		// } else if (hostFlag == 'cba') {
		// 	hostURL = hosts["cba"]
		// } else if (hostFlag == 'nab') {
		// 	hostURL = hosts["nab"]
		// } else if (hostFlag == 'wbc') {
		// 	hostURL = hosts["wbc"]
		// } else {
		// 	console.log("Error.")
		// }

		// var queryParameters = "/" + productIdTransfer	


		var queryParameters =  "https://s3-ap-southeast-2.amazonaws.com/banks.fyi/data/" + hostFlag + "/" + productIdTransfer + ".json"


		let callProductDetails = getProductDetails()

		async function getProductDetails() {


			// const response = await fetch(hostURL+endPoint+queryParameters, {
			// 	headers: myHeaders
			// })

			// const response = await fetch(queryParameters, {
			// })


			let response = await new Promise(resolve => {
			var xhr = new XMLHttpRequest();
			xhr.open("GET", queryParameters, true);
			xhr.onload = function() {
				resolve(xhr.response);
			};
			xhr.onerror = function () {
				resolve(undefined);
				console.error("** An error occurred during the XMLHttpRequest");
			};
			xhr.send();
			}) 
			
			productDetailData = JSON.parse(response)

			// productDetailData = await response.json()
			// if (response.ok) {

				// for (var item in productDetailData.data.lendingRates) {
				// 	console.log(productDetailData.data.lendingRates[item])
				// }




				// for (var item in productDetailData.data) {
				// 	console.log(productDetailData.data[item])
				// }


				// Mandatory stuff

				$nameStore = productDetailData.data.name
				$descriptionStore = productDetailData.data.description
				$isTailoredStore = productDetailData.data.isTailored

				// optional stuff

				//manipulate rates if they exist (Check if they are already in percent first - need to add this check)
				var lendingRoundNumb
				var lendingCompRoundNumb
				var depositRoundNumb
				var feeRoundNumb

				if (productDetailData.data.lendingRates !== undefined) {
					for (var item in productDetailData.data.lendingRates) {
						lendingRoundNumb = productDetailData.data.lendingRates[item].rate * 100						
						productDetailData.data.lendingRates[item].rate = +lendingRoundNumb.toFixed(4)
					}
				}

				if (productDetailData.data.lendingRates !== undefined) {
					for (var item in productDetailData.data.lendingRates) {
						if (productDetailData.data.lendingRates[item].comparisonRate !== undefined) {
						lendingRoundNumb = productDetailData.data.lendingRates[item].comparisonRate * 100
						productDetailData.data.lendingRates[item].comparisonRate = +lendingRoundNumb.toFixed(4)
						}
					}
				}				

				if (productDetailData.data.depositRates !== undefined) {
					for (var item in productDetailData.data.depositRates) {
						depositRoundNumb = productDetailData.data.depositRates[item].rate * 100
						productDetailData.data.depositRates[item].rate = +depositRoundNumb.toFixed(4)
					}
				}


				// add null check for nab

				// if (productDetailData.data.fees !== undefined) {
				// 	for (var item in productDetailData.data.fees) {
						
				// 		if (productDetailData.data.fees[item].balanceRate !== undefined) {
				// 			feeRoundNumb = productDetailData.data.fees[item].balanceRate * 100
				// 			productDetailData.data.fees[item].balanceRate = +feeRoundNumb.toFixed(4)
				// 		}

				// 		if (productDetailData.data.fees[item].transactionRate !== undefined) {
				// 			feeRoundNumb = productDetailData.data.fees[item].transactionRate * 100
				// 			productDetailData.data.fees[item].transactionRate = +feeRoundNumb.toFixed(4)
				// 		}

				// 		if (productDetailData.data.fees[item].accruedRate !== undefined) {
				// 			feeRoundNumb = productDetailData.data.fees[item].accruedRate * 100
				// 			productDetailData.data.fees[item].accruedRate = +feeRoundNumb.toFixed(4)
				// 		}



				// 		// this is for the discounts table that is nested in the fees table
				// 		if (productDetailData.data.fees.discounts !== undefined) {
							
				// 			for (var item in productDetailData.data.fees.discounts) {
								
				// 				if (productDetailData.data.fees.discounts[item].balanceRate !== undefined) {
				// 					feeRoundNumb = productDetailData.data.fees.discounts[item].balanceRate * 100
				// 					productDetailData.data.fees.discounts[item].balanceRate = +feeRoundNumb.toFixed(4)
				// 				}

				// 				if (productDetailData.data.fees.discounts[item].transactionRate !== undefined) {
				// 					feeRoundNumb = productDetailData.data.fees.discounts[item].transactionRate * 100
				// 					productDetailData.data.fees.discounts[item].transactionRate = +feeRoundNumb.toFixed(4)
				// 				}						

				// 				if (productDetailData.data.fees.discounts[item].accruedRate !== undefined) {
				// 					feeRoundNumb = productDetailData.data.fees.discounts[item].accruedRate * 100
				// 					productDetailData.data.fees.discounts[item].accruedRate = +feeRoundNumb.toFixed(4)
				// 				}					

				// 				if (productDetailData.data.fees.discounts[item].feeRate !== undefined) {
				// 					feeRoundNumb = productDetailData.data.fees.discounts[item].feeRate * 100
				// 					productDetailData.data.fees.discounts[item].feeRate = +feeRoundNumb.toFixed(4)
				// 				}					

				// 			}

				// 		}		

				// 	}

				// }		


				$lendingRatesStore = productDetailData.data.lendingRates
				$depositRatesStore = productDetailData.data.depositRates
				$feesStore = productDetailData.data.fees
				$featuresStore = productDetailData.data.features
				$eligibilityStore = productDetailData.data.eligibility
				$constraintsStore = productDetailData.data.constraints
				$bundlesStore = productDetailData.data.bundles
				$applicationUriStore = productDetailData.data.applicationUri
				$effectiveFromStore = productDetailData.data.effectiveFrom
				$effectiveToStore = productDetailData.data.effectiveTo
				// $lastUpdatedStore = productDetailData.data.lastUpdated
				$lastUpdatedStore = new Date(productDetailData.data.lastUpdated)				
				$additionalInformationStore = productDetailData.data.additionalInformation


				// add records number

				if (productDetailData.data.depositRates !== undefined) {
					var depoSize = Object.keys(productDetailData.data.depositRates).length
					$depositRatesRecordsStore = depoSize
				} else {
					$depositRatesRecordsStore = 0
				}

				if (productDetailData.data.lendingRates !== undefined) {
					var lendSize = Object.keys(productDetailData.data.lendingRates).length
					$lendingRatesRecordsStore = lendSize
				} else {
					$lendingRatesRecordsStore = 0
				}
			
				if (productDetailData.data.fees !== undefined) {
					var feesSize = Object.keys(productDetailData.data.fees).length
					$feesRecordsStore = feesSize
				} else {
					$feesRecordsStore = 0
				}

				if (productDetailData.data.features !== undefined) {
					var featuresSize = Object.keys(productDetailData.data.features).length
					$featuresRecordsStore = featuresSize
				} else {
					$featuresRecordsStore = 0
				}

				if (productDetailData.data.eligibility !== undefined) {
					var eligibilitySize = Object.keys(productDetailData.data.eligibility).length
					$eligibilityRecordsStore = eligibilitySize
				} else {
					$eligibilityRecordsStore = 0
				}

				if (productDetailData.data.constraints !== undefined) {
					var constraintsSize = Object.keys(productDetailData.data.constraints).length
					$constraintsRecordsStore = constraintsSize
				} else {
					$constraintsRecordsStore = 0
				}

				if (productDetailData.data.bundles !== undefined) {
					var bundlesSize = Object.keys(productDetailData.data.bundles).length
					$bundlesRecordsStore = bundlesSize
				} else {
					$bundlesRecordsStore = 0
				}

			
				open(Card,
					{ 
						name: $nameStore,
						description: $descriptionStore,
						isTailored: $isTailoredStore,
						lendingRates: $lendingRatesStore,
						depositRates: $depositRatesStore,
						fees: $feesStore,
						features: $featuresStore,
						eligibility: $eligibilityStore,
						constraints: $constraintsStore,
						bundles: $bundlesStore,
						applicationUri: $applicationUriStore,
						effectiveFrom: $effectiveFromStore,
						effectiveTo: $effectiveToStore,
						lastUpdated: $lastUpdatedStore.toDateString(),
						additionalInformation: $additionalInformationStore,
						overviewUri: $additionalInformationStore.overviewUri,
						termsUri: $additionalInformationStore.termsUri,
						eligibilityUri: $additionalInformationStore.eligibilityUri,
						feesAndPricingUri: $additionalInformationStore.feesAndPricingUri,
						bundleUri: $additionalInformationStore.bundleUri,
						depositRatesRecords: $depositRatesRecordsStore,
						lendingRatesRecords: $lendingRatesRecordsStore,
						feesRecords: $feesRecordsStore,
						featuresRecords: $featuresRecordsStore,
						eligibilityRecords: $eligibilityRecordsStore,
						constraintsRecords: $constraintsRecordsStore,
						bundlesRecords: $bundlesRecordsStore
					},
					{},
					{
						onOpen: () => {
						},
						onOpened: () => {
							document.documentElement.style.overflow = "hidden"
							document.body.scroll = "no"

							// specific code for older safari versions
							const scrollableElement = document.querySelector('#modal-window');
							scrollLock.disablePageScroll(scrollableElement);

						},
						onClose: () => {
						},
						onClosed: () => {
							document.documentElement.style.overflow = "scroll"
							document.body.scroll = "yes"

							// specific code for older safari versions
							const scrollableElement = document.querySelector('#modal-window');				
							scrollLock.enablePageScroll(scrollableElement);
						}
					}
				);

				// return productDetailData;

			// } else {
			// 	throw new Error(productDetailData)
			// }
		}

		// setTimeout(() => {

		// 	console.log({$nameStore})

		// }, 2000);

		// console.log({$nameStore})
					

		};
		


</script>

<style></style>

<button class="button is-rounded is-small" style="margin:0.5rem 0.05rem 0.25rem 0.05rem;" on:click={showCard}>Info</button>

<!-- Maybe add button to apply here? -->

<!-- <a on:click={showCard} style="display:block">dd</a> -->
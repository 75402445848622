<script>
	import { onMount } from "svelte";
	import { afterUpdate } from 'svelte';

	import tippy from 'tippy.js'

	import Content from './Content.svelte';
	import ContentTwo from './ContentTwo.svelte';

	// Use your own modal inside src instead of from NPM
	// import Modal from 'svelte-simple-modal';
	import Modal from './Modal.svelte';
  
  // Use your own svelte-select from forked github instead of from NPM
	import Select from 'svelte-select';

	import * as anzProductlist from '../public/data/anz/anzProductListResponse.json'
	import * as cbaProductlist from '../public/data/cba/cbaProductListResponse.json'
	import * as nabProductlist from '../public/data/nab/nabProductListResponse.json'
	import * as wbcProductlist from '../public/data/wbc/wbcProductListResponse.json'

	const productItems = [
		{value: 'TRANS_AND_SAVINGS_ACCOUNTS', label: 'Transaction & Savings Accounts', group: 'Deposit Products'},
		{value: 'TERM_DEPOSITS', label: 'Term Deposits', group: 'Deposit Products'},
		// {value: 'TRAVEL_CARDS', label: 'Travel Cards', group: 'Deposit Products'},
		// {value: 'REGULATED_TRUST_ACCOUNTS', label: 'Regulated Trust Accounts', group: 'Deposit Products'},
		{value: 'RESIDENTIAL_MORTGAGES', label: 'Residential Mortgages', group: 'Lending Products'},
		{value: 'CRED_AND_CHRG_CARDS', label: 'Credit & Charge Cards', group: 'Lending Products'},
		{value: 'PERS_LOANS', label: 'Personal Loans', group: 'Lending Products'}
		// {value: 'MARGIN_LOANS', label: 'Margin Loans', group: 'Lending Products'},
		// {value: 'LEASES', label: 'Leases', group: 'Lending Products'},
		// {value: 'TRADE_FINANCE', label: 'Trade Finance', group: 'Lending Products'},
		// {value: 'OVERDRAFTS', label: 'Overdrafts', group: 'Lending Products'},
		// {value: 'BUSINESS_LOANS', label: 'Business Loans', group: 'Lending Products'}
	];

	const groupBy = (item) => item.group;
	
	// Default to term_and_savings_account
	let selectedValue = productItems[0];

	import { 
		hosts,
		endPoint,
		myHeaders,
		pageSize,
		effective,
		depositsViewStore,
		lendingsViewStore,
		feesViewStore,
		featuresViewStore,
		eligibilityViewStore,
		constraintsViewStore,
		bundlesViewStore,
		depositsOrderStore,
		lendingOrderStore,
		feesOrderStore,
		featuresOrderStore,
		eligibilityOrderStore,
		constraintsOrderStore,
		bundlesOrderStore,
		trackCardOrderStore
	} from './stores.js'


	// set up card views
	$depositsViewStore = true
	$lendingsViewStore = true
	$feesViewStore = true
	$featuresViewStore = false
	$eligibilityViewStore = false
	$constraintsViewStore = false
	$bundlesViewStore = false

	//set up card orders
	$depositsOrderStore = 0
	$lendingOrderStore = 1
	$feesOrderStore = 2
	$featuresOrderStore = 3
	$eligibilityOrderStore = 4
	$constraintsOrderStore = 5
	$bundlesOrderStore = 6
	$trackCardOrderStore = 0


	//set up default bank views
	let showAnz = true
	let showCba = true
	let showNab = false
	let showWbc = false

	//set up order values
	let anzOrder = 0
	let cbaOrder = 1
	let nabOrder = 2
	let wbcOrder = 3

	//order counter
	let trackWindowOrder = 0

	var queryParameters = "?" + "page-size=" + pageSize + "&effective=" + effective

	// ******* COMMENTED OUT (declare product list - use for fetching) COMMENTED OUT *********
	
	// var anzProductlist
	// var cbaProductlist
	// var nabProductlist
	// var wbcProductlist


// ##### FUNCTION STYLE #####


	let callAnzList = getAnzList()

	async function getAnzList() {
		
		// const response = await fetch(hosts["anz"]+endPoint+queryParameters, {
		// 	method: 'GET',
		// 	mode: 'cors',
		// 	headers: myHeaders,
		// 	// headers: {
		// 	// 	'Access-Control-Allow-Methods': '*',
		// 	// 	'Access-Control-Allow-Headers': 'x-v',
		// 	// 	'x-v': '1',
		// 	// }
		// })
		// anzProductlist = await response.json()

		// if (response.ok) {

		// 	console.log(anzProductlist)
		// 	return anzProductlist;

		// } else {
		// 	console.log(anzProductlist)
		// 	throw new Error(anzProductlist)
		// }

		return anzProductlist;
		
	}


	let callCbaList = getCbaList()

	async function getCbaList() {
		
		// const response = await fetch(hosts["cba"]+endPoint+queryParameters, {
		// 	method: 'GET',
		// 	mode: 'cors',
		// 	headers: myHeaders
		// })
		// cbaProductlist = await response.json()

		// if (response.ok) {
		// 	console.log(cbaProductlist)
		// 	return cbaProductlist;

		// } else {
		// 	console.log(cbaProductlist)
		// 	throw new Error(cbaProductlist)
		// }

		return cbaProductlist	

	}

	let callNabList = getNabList()

	async function getNabList() {

		// const response = await fetch(hosts["nab"]+endPoint+queryParameters, {
		// 	method: 'GET',
		// 	mode: 'cors',
		// 	headers: myHeaders
		// })
		// nabProductlist = await response.json()

		// if (response.ok) {
		// 	console.log(nabProductlist)
		// 	return nabProductlist;

		// } else {
		// 	console.log(nabProductlist)
		// 	throw new Error(nabProductlist)
		// }

		return nabProductlist;

	}


	let callWbcList = getWbcList()

	async function getWbcList() {

		// const response = await fetch(hosts["wbc"]+endPoint+queryParameters, {
		// 	method: 'GET',
		// 	mode: 'cors',
		// 	headers: myHeaders
		// })
		// wbcProductlist = await response.json()

		// if (response.ok) {
		// 	console.log(wbcProductlist)
		// 	return wbcProductlist;

		// } else {
		// 	console.log(wbcProductlist)
		// 	throw new Error(wbcProductlist)
		// }

		return wbcProductlist;

	}

// setTimeout(() => {

// 	for (var item in anzProductlist.data) {
// 		console.log(anzProductlist.data[item])
// 	}

// }, 2000);


	function anzDecreaseCounter() {
		trackWindowOrder = trackWindowOrder - 1
		anzOrder = trackWindowOrder
	}

	function cbaDecreaseCounter() {
		trackWindowOrder = trackWindowOrder - 1
		cbaOrder = trackWindowOrder
	}

	function nabDecreaseCounter() {
		trackWindowOrder = trackWindowOrder - 1
		nabOrder = trackWindowOrder
	}

	function wbcDecreaseCounter() {
		trackWindowOrder = trackWindowOrder - 1
		wbcOrder = trackWindowOrder
	}	



	function handleAnzClose() {
		showAnz = false
	}

	function handleCbaClose() {
		showCba = false
	}

	function handleNabClose() {
		showNab = false
	}

	function handleWbcClose() {
		showWbc = false
	}




	function sortChildrenDivsById() {
		const main = document.querySelector('#bankcolumns');
		var divs = [...main.children];

		let mql = window.matchMedia('(max-width: 768px)');

		if (mql.matches) {

			var visibleCount = 0

			if (showAnz == true) {
				visibleCount = visibleCount + 1
			}
			if (showCba == true) {
				visibleCount = visibleCount + 1
			}
			if (showNab == true) {
				visibleCount = visibleCount + 1
			}
			if (showWbc == true) {
				visibleCount = visibleCount + 1
			}						

			if (visibleCount == 3) {
		

				divs.sort((a, b) => a.id - b.id);
				
				if (divs[2].firstChild.id == 'nab') {
					showNab = false
				} else if (divs[2].firstChild.id == 'anz') {
					showAnz = false
				} else if (divs[2].firstChild.id == 'wbc') {
					showWbc = false
				} else if (divs[2].firstChild.id == 'cba') {
					showCba = false
				}

				divs = divs.slice(0,2)
				
			} else if (visibleCount == 4) {

				// console.log(divs.slice(0,2))
				// console.log("visible count 4")

				divs.sort((a, b) => a.id - b.id);

				if (divs[3].firstChild.id == 'nab') {
					showNab = false
				} else if (divs[3].firstChild.id == 'anz') {
					showAnz = false
				} else if (divs[3].firstChild.id == 'wbc') {
					showWbc = false
				} else if (divs[3].firstChild.id == 'cba') {
					showCba = false
				}

				if (divs[2].firstChild.id == 'nab') {
					showNab = false
				} else if (divs[2].firstChild.id == 'anz') {
					showAnz = false
				} else if (divs[2].firstChild.id == 'wbc') {
					showWbc = false
				} else if (divs[2].firstChild.id == 'cba') {
					showCba = false
				}

				divs = divs.slice(0,2)

			} 
		}

		divs.sort((a, b) => a.id - b.id);
		divs.forEach(div => main.appendChild(div));
			

	}


		// console.log(divs)
		// console.log(divs.length)

		// divs.sort((a, b) => a.id - b.id);


		// console.log(divs)

		// divs.forEach(div => main.appendChild(div));


		// let firstChild = parentElement.firstChild; 

		// document.querySelector(".mq-value").innerText = mql.matches;



	afterUpdate(() => {
		sortChildrenDivsById()
	});








	function myFunction(x) {
		
		if (x.matches) { // If media query matches

			const main = document.querySelector('#bankcolumns');

			if (main !== null) {

				var divs = [...main.children];

				// document.body.style.backgroundColor = "yellow";
				
				var visibleCount = 0

				if (showAnz == true) {
					visibleCount = visibleCount + 1
				}
				if (showCba == true) {
					visibleCount = visibleCount + 1
				}
				if (showNab == true) {
					visibleCount = visibleCount + 1
				}
				if (showWbc == true) {
					visibleCount = visibleCount + 1
				}						

				if (visibleCount == 3) {
			
					divs.sort((a, b) => a.id - b.id);
					
					if (divs[2].firstChild.id == 'nab') {
						showNab = false
					} else if (divs[2].firstChild.id == 'anz') {
						showAnz = false
					} else if (divs[2].firstChild.id == 'wbc') {
						showWbc = false
					} else if (divs[2].firstChild.id == 'cba') {
						showCba = false
					}

					divs = divs.slice(0,2)
					divs.sort((a, b) => a.id - b.id);
					divs.forEach(div => main.appendChild(div));
					
				} else if (visibleCount == 4) {

					// console.log(divs.slice(0,2))
					// console.log("visible count 4")

					divs.sort((a, b) => a.id - b.id);

					if (divs[3].firstChild.id == 'nab') {
						showNab = false
					} else if (divs[3].firstChild.id == 'anz') {
						showAnz = false
					} else if (divs[3].firstChild.id == 'wbc') {
						showWbc = false
					} else if (divs[3].firstChild.id == 'cba') {
						showCba = false
					}

					if (divs[2].firstChild.id == 'nab') {
						showNab = false
					} else if (divs[2].firstChild.id == 'anz') {
						showAnz = false
					} else if (divs[2].firstChild.id == 'wbc') {
						showWbc = false
					} else if (divs[2].firstChild.id == 'cba') {
						showCba = false
					}

					divs = divs.slice(0,2)
					divs.sort((a, b) => a.id - b.id);
					divs.forEach(div => main.appendChild(div));

				}

				} else {
					
				// document.body.style.backgroundColor = "pink";
				
			}

		}

	}

	var x = window.matchMedia("(max-width: 768px)")
	myFunction(x) // Call listener function at run time
	x.addListener(myFunction) // Attach listener function on state changes



  const tippyTitleInfo = {
	content: "banks.fyi pulls data directly from Australia's four largest banks and consolidates it here for an interactive experience. Learn more about <a style=\"color:#6666ff\" href=\"https://www.accc.gov.au/focus-areas/consumer-data-right-cdr-0\" target=\"_blank\">Open Banking</a>",
	allowHTML: true,
	interactive: true,
	placement: "bottom",
	interactiveBorder: 30,
  };


</script>


<style>

	.logo-text {
		color: #fff;
		width: 14em;
		text-align: left;
		margin-left: 1em;
	}


	.product-list-styling {
		--borderRadius: 25px;
		--listMaxHeight: 250px;
		--height:32px;
		/* --itemPadding: 1em; */
		/* --groupTitlePadding:1em; */
		--groupTitleColor: black;
		--groupTitleTextTransform: uppercase;
		--groupTitleFontWeight: 600;
		--groupItemPaddingLeft: 20px;

		--selectedItemFontSize: 1.1em;
		--selectedItemFontWeight: 600;
		--groupTitleFontSize: 1em;
		--groupItemFontSize: 500;

		--itemColor: #5f5f5f;
		--indicatorTop: 5px;
		/* --indicatorTop: 10px; */
	}

	.product-spacing {
		margin-top:30px;
	}

	.banks-spacing {
		/* margin-top: 30px; */
		margin-bottom:20px;
	}

	.banks-column {
		padding-left:6px;
		padding-right:6px;
	}


	/* Space out table rows */
	table { 
		border-collapse: separate; 
		border-spacing: 0 2px; 
		margin-top: -2x; /* correct offset on first border spacing if desired */
	}
	td {
		border: 1px solid #f8f9fb;
		padding: 0.25em 0.5em 0.25em 0.5em;
	}
	td:first-child {
		border-left-style: solid;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	td:last-child {
		border-right-style: solid;
		border-bottom-right-radius: 10px; 
		border-top-right-radius: 10px; 
	}


	.apply-button-style {
		margin:0.5rem 0.05rem 0.25rem 0.05rem;
		background-color:#3ecf8e;
		color:#fff;
		text-shadow: 0 1px 3px rgba(36,180,126,.4);
	}


	.close-button {
		background-color:#ffffff;
		border-radius:25px;
		padding: 0px 10px 0px 15px;
		height: 2em;
		border:1px solid #cccccc;
	}

	.close-button:hover {
		background-color:#e6e6e6;
		border-color: #adadad;
	}	



	.gg-close {
		box-sizing: border-box;
		position: relative;
		display: block;
		transform: scale(var(--ggs,1));
		width: 22px;
		height: 22px;
		border: 2px solid transparent;
		border-radius: 40px
	}

	.gg-close::after,
	.gg-close::before {
		content: "";
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 16px;
		height: 2px;
		background: currentColor;
		transform: rotate(45deg);
		border-radius: 5px;
		top: 8px;
		left: 1px
	}

	.gg-close::after {
		transform: rotate(-45deg)
	} 

	.gg-info {
		box-sizing: border-box;
		position: relative;
		display: block;
		transform: scale(0.9);
		width: 20px;
		height: 20px;
		border: 2px solid #b7b7b7;
		border-radius: 40px;
	}

	.gg-info:hover {
		/* background:#000000; */
		border: 2px solid #000000;
  }  

	.gg-info::after,
	.gg-info::before {
		content: "";
		display: block;
		box-sizing: border-box;
		position: absolute;
		border-radius: 3px;
		width: 2px;
	/* background: currentColor; */
		background: #b7b7b7;
		left: 7px
	}

	.gg-info::after {
		bottom: 2px;
		height: 8px
	}

	.gg-info::before {
		height: 2px;
		top: 2px
  } 

	.gg-info:hover::after {
		bottom: 2px;
		height: 8px;
		background: #000000;
	} 
  
	.gg-info:hover::before {
		height: 2px;
		top: 2px;
		background: #000000;
	}   


	.table-header {
		font-size: 0.825em;
	}

	.table-text {
		font-size: 0.75em;
	}

</style>


<main>

	<section class="section has-text-centered" style="padding-top:2rem; padding-bottom:2rem; background-color:#5d6d7e">
		
		<div class="container">
			<div class="columns is-mobile is-centered is-vcentered">
				<a href="https://www.banks.fyi">
					<figure class="image is-48x48">
						<img alt="banks.fyi Logo" src="img/full-logo/banks-fyi-logo-white.svg">
					</figure>
				</a>
				<h6 class="title is-6 logo-text">
					<span>Explore banking products using Open Banking</span>
					<span class="icon">
						<i use:tippy={tippyTitleInfo} class="gg-info" style="top:4px; left:-2px"></i>
					</span>
				</h6>
			</div>
		</div>


		<div class="container responsive-product product-list-styling product-spacing">
		<!-- select product category -->
			<Select items={productItems} isClearable={false} isSearchable={false} bind:selectedValue></Select>
		</div>


	</section>


	<section class="section has-text-centered" style="padding-top:1.5rem; padding-bottom:1.5rem;">


		<!-- Select which banks -->
		<div class="container banks-spacing">
			<div class="columns is-mobile is-centered">
				
				<div>

					{#if showAnz}
						<label class="click-button-active">
							<input type="checkbox" hidden bind:checked={showAnz}>
								<img alt="ANZ Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px" src="img/small-logo/anz-small-logo.svg">
								<span class="active-text is-hidden-mobile">ANZ</span>
						</label>								
					{:else}
						<label class="click-button">
							<input type="checkbox" hidden bind:checked={showAnz} on:change={anzDecreaseCounter} on:click={anzDecreaseCounter}>
								<img alt="ANZ Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px" src="img/small-logo/anz-small-logo.svg">
								<span class="is-hidden-mobile">ANZ</span>
						</label>			
					{/if}

				</div>

				<div>

					{#if showCba}
						<label class="click-button-active">
							<input type="checkbox" hidden bind:checked={showCba}>
								<img alt="CBA Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px" src="img/small-logo/cba-small-logo.svg">
								<span class="active-text is-hidden-mobile">CBA</span>
						</label>								
					{:else}
						<label class="click-button">
							<input type="checkbox" hidden bind:checked={showCba} on:change={cbaDecreaseCounter} on:click={cbaDecreaseCounter}>
								<img alt="CBA Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px" src="img/small-logo/cba-small-logo.svg">
								<span class="is-hidden-mobile">CBA</span>
						</label>			
					{/if}

				</div>

				<div>

					{#if showNab}
						<label class="click-button-active">
							<input type="checkbox" hidden bind:checked={showNab}>
								<img alt="NAB Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px" src="img/small-logo/nab-small-logo.svg">
								<span class="active-text is-hidden-mobile">NAB</span>
						</label>								
					{:else}
						<label class="click-button">
							<input type="checkbox" hidden bind:checked={showNab} on:change={nabDecreaseCounter} on:click={nabDecreaseCounter}>
								<img alt="NAB Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px" src="img/small-logo/nab-small-logo.svg">
								<span class="is-hidden-mobile">NAB</span>
						</label>			
					{/if}

				</div>

				<div>

					{#if showWbc}
						<label class="click-button-active">
							<input type="checkbox" hidden bind:checked={showWbc}>
								<img alt="Wbc Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px;margin-bottom:3px;" src="img/small-logo/wbc-small-logo.svg">
								<span class="active-text is-hidden-mobile">WBC</span>
						</label>								
					{:else}
						<label class="click-button">
							<input type="checkbox" hidden bind:checked={showWbc} on:change={wbcDecreaseCounter} on:click={wbcDecreaseCounter}>
								<img alt="Wbc Logo" width="13" height="13" style="margin-right:5px;margin-left:5px;margin-top:-4px;margin-bottom:3px;" src="img/small-logo/wbc-small-logo.svg">
								<span class="is-hidden-mobile">WBC</span>
						</label>			
					{/if}

				</div>

			</div>

		</div>



		<div class ="container">
			<div id="bankcolumns" class="columns is-mobile is-centered">

				{#if showAnz}
					<div id={anzOrder} class="column banks-column">
						<table id="anz" class="table is-hoverable" style="width:100%;">
							<tbody>
								{#await callAnzList}
									<p>Loading...</p>
								{:then anzProductlist}
								
									<div style="margin-bottom:12px">
										
										<button class="button close-button" on:click={handleAnzClose}>
											<span class="icon">
												<i class="gg-close"></i>
											</span>
											<span>ANZ</span>
										</button>

									</div>
								
									{#each anzProductlist.data.products as {additionalInformation, applicationUri, brand, description, effectiveFrom, isTailored, lastUpdated, name, productCategory, productId}}

										{#if productCategory == selectedValue.value}
											<tr>
												<td class="has-text-centered" style="background-color:#eef6fc">
													<strong class="table-header">{name}</strong>
													<p class="table-text">{description}</p>

													<div class="field is-grouped is-grouped-centered">
													
														{#if applicationUri !== undefined}
														<button onClick="window.open('{applicationUri}');" class="button is-rounded is-small apply-button-style">Apply</button>
														{/if}

														<Modal>
															<Content productIdTransfer={productId} hostFlag="anz"/>
														</Modal>
													
													</div>

												</td>
											</tr>
										{/if}

									{/each}

								{/await}
							</tbody>
						</table>
					</div>
				{/if}


				{#if showCba}
					<div id={cbaOrder} class="column banks-column">
						<table id="cba" class="table is-hoverable" style="width:100%;">
							<tbody>
								{#await callCbaList}
									<p>Loading...</p>
								{:then cbaProductlist}

									<div style="margin-bottom:12px">
										
										<button class="button close-button" on:click={handleCbaClose}>
											<span class="icon">
												<i class="gg-close"></i>
											</span>
											<span>CBA</span>
										</button>

									</div>

									{#each cbaProductlist.data.products as {additionalInformation, applicationUri, brand, description, effectiveFrom, isTailored, lastUpdated, name, productCategory, productId}}

										{#if productCategory == selectedValue.value}
											<tr>
												<td class="has-text-centered" style="background-color:#fffbeb">
													<strong class="table-header">{name}</strong>
													<p class="table-text">{description}</p>

													<div class="field is-grouped is-grouped-centered">
													
														{#if applicationUri !== undefined}
														<button onClick="window.open('{applicationUri}');" class="button is-rounded is-small apply-button-style">Apply</button>
														{/if}

														<Modal>
															<Content productIdTransfer={productId} hostFlag="cba"/>
														</Modal>
													
													</div>
													

												</td>
											</tr>
										{/if}

									{/each}

								{/await}
							</tbody>
						</table>
					</div>
				{/if}


				{#if showNab}
					<div id={nabOrder} class="column banks-column">
						<table id="nab" class="table is-hoverable" style="width:100%;">
							<tbody>
								{#await callNabList}
									<p>Loading...</p>
								{:then nabProductlist}

									<div style="margin-bottom:12px">
										
										<button class="button close-button" on:click={handleNabClose}>
											<span class="icon">
												<i class="gg-close"></i>
											</span>
											<span>NAB</span>
										</button>

									</div>

									{#each nabProductlist.data.products as {additionalInformation, applicationUri, brand, description, effectiveFrom, isTailored, lastUpdated, name, productCategory, productId}}

										{#if productCategory == selectedValue.value}
											<tr>
												<td class="has-text-centered" style="background-color:#feecf0">
													<strong class="table-header">{name}</strong>
													<p class="table-text">{description}</p>

													<div class="field is-grouped is-grouped-centered">
													
														{#if applicationUri !== undefined}
														<button onClick="window.open('{applicationUri}');" class="button is-rounded is-small apply-button-style">Apply</button>
														{/if}

														<Modal>
															<Content productIdTransfer={productId} hostFlag="nab"/>
														</Modal>
													
													</div>

												</td>
											</tr>
										{/if}

									{/each}

								{/await}
							</tbody>
						</table>
					</div>
				{/if}


				{#if showWbc}
					<div id={wbcOrder} class="column banks-column">
						<table id="wbc" class="table is-hoverable" style="width:100%;">
							<tbody>
								{#await callWbcList}
									<p>Loading...</p>
								{:then wbcProductlist}

									<div style="margin-bottom:12px">
										
										<button class="button close-button" on:click={handleWbcClose}>
											<span class="icon">
												<i class="gg-close"></i>
											</span>
											<span>WBC</span>
										</button>

									</div>								

									{#each wbcProductlist.data.products as {additionalInformation, applicationUri, brand, description, effectiveFrom, isTailored, lastUpdated, name, productCategory, productId}}

										{#if productCategory == selectedValue.value}
											<tr>
												<td class="has-text-centered" style="background-color:#f5f5f5">
													<strong class="table-header">{name}</strong>
													<p class="table-text">{description}</p>

													<div class="field is-grouped is-grouped-centered">
													
														{#if applicationUri !== undefined}
														<button onClick="window.open('{applicationUri}');" class="button is-rounded is-small apply-button-style">Apply</button>
														{/if}
														
														<Modal>
															<Content productIdTransfer={productId} hostFlag="wbc"/>
														</Modal>
													
													</div>

												</td>
											</tr>
										{/if}

									{/each}

								{/await}
							</tbody>
						</table>
					</div>
				{/if}

				
			</div>
		</div>


		<div class="columns is-centered is-gapless" style="padding-top:1rem;">

		<Modal>
			<ContentTwo/>
		</Modal>

		</div>

	</section>

</main>



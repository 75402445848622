<script>
	
	import { getContext } from 'svelte';
	import Faq from './Faq.svelte';
	import Privacy from './Privacy.svelte';

	import scrollLock from 'scroll-lock';

	const { open } = getContext('simple-modal');


	const showFaq = () => {

		open(Faq,
			{ 
					
			},
			{},
			{
				onOpen: () => {
				},
				onOpened: () => {
					document.documentElement.style.overflow = 'hidden'
					document.body.scroll = "no"

					// specific code for older safari versions
					const scrollableElement = document.querySelector('#modal-window');
					scrollLock.disablePageScroll(scrollableElement);

				},
				onClose: () => {
				},
				onClosed: () => {
					document.documentElement.style.overflow = 'scroll'
					document.body.scroll = "yes"

					// specific code for older safari versions
					const scrollableElement = document.querySelector('#modal-window');				
					scrollLock.enablePageScroll(scrollableElement);					
				}
			}
		);

	}


	const showPrivacy = () => {

		open(Privacy,
			{ 
					
			},
			{},
			{
				onOpen: () => {
				},
				onOpened: () => {
					document.documentElement.style.overflow = 'hidden'
					document.body.scroll = "no"

					// specific code for older safari versions
					const scrollableElement = document.querySelector('#modal-window');
					scrollLock.disablePageScroll(scrollableElement);					
				},
				onClose: () => {
				},
				onClosed: () => {
					document.documentElement.style.overflow = 'scroll'
					document.body.scroll = "yes"

					// specific code for older safari versions
					const scrollableElement = document.querySelector('#modal-window');				
					scrollLock.enablePageScroll(scrollableElement);					
				}
			}
		);

	}

</script>

<style>
	.main-footer-links {
	font-size: 0.9em;
	margin-right: 14px;
	margin-left: 14px;
	}

</style>

<a on:click={showFaq} class="main-footer-links">FAQ</a>
<a href="mailto:john.trieu.nguyen@gmail.com" class="main-footer-links">Contact</a>
<a on:click={showPrivacy} class="main-footer-links">Privacy</a>

<!-- Maybe add button to apply here? -->

<!-- <a on:click={showCard} style="display:block">dd</a> -->